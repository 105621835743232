import React from 'react';
import PropTypes from 'prop-types';
import FALLBACK_APP_ICON from '../constants';
import styles from './AppIcon.module.scss';

const AppIcon = ({ src = FALLBACK_APP_ICON, alt = '', size = '64px' }) => (
  <picture
    className={styles.icon__container}
    style={{ width: size, height: size }}
  >
    <img alt={alt} className={styles['app-icon']} src={src} />
  </picture>
);

AppIcon.propTypes = {
  alt: PropTypes.string,
  size: PropTypes.string.isRequired,
  src: PropTypes.string.isRequired,
};

AppIcon.defaultProps = {
  alt: '',
};

export default AppIcon;
