import React, { useState } from 'react';
import PropTypes from 'prop-types';
import ReactModal from 'react-modal';
import { ModalHeader, ModalBody, ModalFooter } from '@thinkific/toga-modal';
import Button from '@thinkific/toga-button';
import Input from '@thinkific/toga-input';
import { useUiContext } from '../../UiContext';
import withMutation from '../../../hocs/withMutation';
import ConnectClientSiteMutation from '../../../graphql/Mutation/ConnectClientSite';
import {
  validateFields,
  isValid,
  DEFAULT_ERRORS_CLASSNAME,
} from '../../../utils/validations';
import { graphqlMutationErrors } from '../../../utils/errors';
import validations from './validations';
import { e2eId } from '../../../utils/test.utils';
import Icons from '../../Icons';
import styles from './ConnectClientSiteModal.module.scss';

const RightAccessory = <span>.thinkific.com</span>;

function ConnectClientSiteModal({ mutation }) {
  const { closeModal, showToast } = useUiContext();
  const [subdomain, setSubdomain] = useState('');
  const [validationErrors, setValidationErrors] = useState({});
  const connectClient = mutation.action;

  async function handleRequest() {
    const lastErrors = validateFields({ subdomain }, validations);
    setValidationErrors(lastErrors);

    if (isValid(lastErrors)) {
      try {
        const response = await connectClient({
          variables: {
            subdomain,
          },
        });

        if (response.data) {
          showToast('notice', 'Site connection request sent!');
        }
      } catch (e) {
        showToast('alert', graphqlMutationErrors(e));
      }
      closeModal();
    }
  }

  return (
    <ReactModal
      ariaHideApp={false}
      className={styles.container}
      contentLabel="Modal"
      overlayClassName={styles.overlay}
      isOpen
      onRequestClose={closeModal}
    >
      <ModalHeader
        className={styles.header}
        {...e2eId('siteAccessRequestModalHeader')}
      >
        <h4>Request site connection</h4>
        <Button
          appearance="knockout"
          aria-label="Close"
          className={styles['close-button']}
          onClick={closeModal}
        >
          <Icons.Dismiss />
        </Button>
      </ModalHeader>
      <ModalBody className={styles.body}>
        <p>
          Enter the Thinkific subdomain of the site you wish to connect. An
          email will be sent to the site owner to confirm the connection.
        </p>
        <Input
          extraProps={{ ...e2eId('siteAccessRequestSubdomainInput') }}
          handleChange={(e) => setSubdomain(e.target.value.toLowerCase())}
          id="subdomain"
          initValue={subdomain}
          label="Thinkific subdomain"
          name="subdomain"
          placeholder="subdomain"
          rightAccessory={RightAccessory}
          autoFocus
        />
        {validationErrors.subdomain && validationErrors.subdomain.required && (
          <span
            className={DEFAULT_ERRORS_CLASSNAME}
            {...e2eId('subdomainValidationError')}
          >
            {validationErrors.subdomain.required}
          </span>
        )}
      </ModalBody>
      <ModalFooter className={styles.footer}>
        <Button appearance="secondary" onClick={closeModal}>
          Cancel
        </Button>
        <Button
          appearance="primary"
          onClick={handleRequest}
          {...e2eId('sendSiteAccessRequestButton')}
        >
          Send request
        </Button>
      </ModalFooter>
    </ReactModal>
  );
}

ConnectClientSiteModal.displayName = 'ConnectClientSiteModal';

ConnectClientSiteModal.propTypes = {
  mutation: PropTypes.shape({
    action: PropTypes.func,
    // eslint-disable-next-line
    data: PropTypes.object,
  }),
};

ConnectClientSiteModal.defaultProps = {
  mutation: {
    action: () => {},
    data: {
      loading: false,
      error: false,
      called: false,
    },
  },
};

export { ConnectClientSiteModal };
export default withMutation(ConnectClientSiteModal, ConnectClientSiteMutation, {
  refetchQueries: [`Sites`],
});
