import React from 'react';
import styles from './Header.module.scss';
import HeaderAction from './HeaderAction';
import Breadcrumb from '../Breadcrumb';
import AppIcon from '../Apps/AppIcon';
import { useUiContext } from '../UiContext';
import { e2eId } from '../../utils/test.utils';

const Header = () => {
  const {
    pageProps: {
      breadCrumbLabel,
      breadCrumbPath,
      hasAction,
      hasBackground,
      hasBreadcrumb,
      hasTitle,
      icon,
      singleColumn,
      title,
    },
  } = useUiContext();

  return (
    <header>
      <div
        className={`
        ${styles.container}
        ${singleColumn ? styles['container__single-column'] : ''}
      `}
      >
        {hasBreadcrumb ? (
          <Breadcrumb
            hasBackground={hasBackground}
            label={breadCrumbLabel}
            path={breadCrumbPath}
            singleColumn={singleColumn}
          />
        ) : (
          <div className={styles.spacer} />
        )}
        <div
          className={`${styles.title__container} ${
            hasAction ? styles['title__container--with-action'] : ''
          }`}
        >
          {icon && <AppIcon alt={title || ''} size="48px" src={icon} />}
          {hasTitle && (
            <h1
              {...e2eId('headerTitle')}
              className={`
              ${styles.title}
              ${!hasBackground ? styles['title__no-bg'] : ''}
              `}
            >
              {title}
            </h1>
          )}
          {hasAction && <HeaderAction />}
        </div>
      </div>
    </header>
  );
};

Header.displayName = 'Header';

export default Header;
