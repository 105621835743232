import ApolloClient from 'apollo-boost';
import AppSyncConfig from './aws-appsync';

export const apolloClient = new ApolloClient({
  fetch,
  fetchOptions: {
    credentials: 'include',
  },
  uri: AppSyncConfig.graphqlEndpoint,
  request: async (operation) => {
    operation.setContext({
      headers: {
        Authorization: localStorage.getItem('accessToken'),
      },
    });
  },
});
