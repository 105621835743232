import crypto from 'crypto';

// transforms string to base64 url safe
const base64EncodeUrlSafe = (url) => {
  return url
    .toString('base64')
    .replace(/\+/g, '-')
    .replace(/\//g, '_')
    .replace(/=/g, '');
};

// creates codeChallenge hash using sha256
const sha256 = (codeVerifier) => {
  return crypto.createHash('sha256').update(codeVerifier).digest();
};

export const codeChallengeMethod = 'S256';

// generates random string
// recommended a string with between 43 and 128 characters in size
export const generateCodeVerifier = () => {
  return base64EncodeUrlSafe(crypto.randomBytes(32));
};

// generates codeChallenge
// Base64UrlSafe(SHA256(codeVerifier))
export const generateCodeChallenge = (codeVerifier) => {
  return base64EncodeUrlSafe(sha256(codeVerifier));
};
