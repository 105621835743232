import { Component } from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';

// This component ensures we tell Appcues when there are page transitions
// More info: https://docs.appcues.com/article/389-single-page-applications-spas
class AppcuesTracker extends Component {
  componentDidUpdate(prevProps) {
    const {
      location: { pathname },
    } = this.props;
    const previousLocation = prevProps.location.pathname;

    if (pathname !== previousLocation && window.Appcues) {
      window.Appcues.page();
    }
  }

  render() {
    const { children } = this.props;
    return children;
  }
}

AppcuesTracker.propTypes = {
  children: PropTypes.node.isRequired,
  location: PropTypes.shape({
    pathname: PropTypes.string,
  }).isRequired,
};

export default withRouter(AppcuesTracker);
