/* eslint-disable react/no-unescaped-entities */
import React, { useReducer } from 'react';
import { NavLink } from 'react-router-dom';
import { FormRow, Col } from '@thinkific/toga-grid';
import Button from '@thinkific/toga-button';
import Input from '@thinkific/toga-input';
import { CognitoUserPool, CognitoUser } from 'amazon-cognito-identity-js';
import ForgotPasswordForm from './ForgotPasswordForm';
import SuccessAlert from './SuccessAlert';
import WarningAlert from './WarningAlert';
import {
  EMAIL_REGEX,
  MESSAGES,
  TITLES,
  TYPES,
  PASSWORD_REGEX,
  PASSWORD_LENGTH,
} from './constants';
import styles from './ForgotPassword.module.scss';
import { DEFAULT_ERRORS_CLASSNAME } from '../../../utils/validations';
import AWSCognitoConfig from '../../../config/aws-cognito';

const initialState = {
  alertType: null,
  code: '',
  email: '',
  isInvalidCode: false,
  isInvalidEmail: false,
  isInvalidPassword: false,
  message: '',
  password: '',
  title: TITLES.STEP_ONE,
};

const reducer = (state, newState) => {
  return {
    ...state,
    ...newState,
  };
};

const ForgotPassword = () => {
  const [state, dispatch] = useReducer(reducer, initialState);
  const {
    alertType,
    code,
    email,
    isInvalidCode,
    isInvalidEmail,
    isInvalidPassword,
    message,
    password,
    title,
  } = state;

  const getCognitoUser = (userData) => {
    return new CognitoUser(userData);
  };

  const getUserPool = () => {
    return new CognitoUserPool({
      ClientId: AWSCognitoConfig.clientId,
      UserPoolId: AWSCognitoConfig.userPoolId,
    });
  };

  const handleCodeChange = (e) => {
    dispatch({ code: e.target.value });
  };

  const handlePasswordChange = (e) => {
    dispatch({ password: e.target.value });
  };

  const handleEmailChange = (e) => {
    dispatch({ email: e.target.value });
  };

  const handleOnSubmit = (event) => {
    event.preventDefault();
    if (!email.match(EMAIL_REGEX)) {
      dispatch({ isInvalidEmail: true });
      return;
    }

    dispatch({ isInvalidEmail: false });
    const cognitoUser = getCognitoUser({
      Username: email,
      Pool: getUserPool(),
    });

    cognitoUser.forgotPassword({
      onSuccess() {
        dispatch({
          alertType: TYPES.INFO,
          message: MESSAGES.INFO,
          title: TITLES.STEP_TWO,
        });
      },
      onFailure(error) {
        dispatch({
          alertType: TYPES.WARNING,
          message: error.message,
        });
      },
    });
  };

  const handleResetState = () => {
    dispatch(initialState);
  };

  const handleResetPassword = (event) => {
    event.preventDefault();
    if (password.length < PASSWORD_LENGTH || !password.match(PASSWORD_REGEX)) {
      dispatch({ isInvalidPassword: true });
      return;
    }

    dispatch({ isInvalidPassword: false });
    const cognitoUser = getCognitoUser({
      Username: email,
      Pool: getUserPool(),
    });

    cognitoUser.confirmPassword(code, password, {
      onSuccess: () => {
        dispatch({
          alertType: TYPES.SUCCESS,
          isInvalidCode: false,
          message: MESSAGES.SUCCESS,
        });
      },
      onFailure: (error) => {
        dispatch({
          alertType: TYPES.INFO,
          isInvalidCode: true,
          message: error.message,
        });
      },
    });
  };

  return (
    <>
      <div className="Welcome__Header">
        <h1>{title}</h1>
      </div>
      <div className="Welcome__Surface mb-5">
        {message.length ? (
          <>
            {alertType === TYPES.SUCCESS && <SuccessAlert />}
            {alertType === TYPES.WARNING && (
              <WarningAlert message={message} onResetState={handleResetState} />
            )}
            {alertType === TYPES.INFO && (
              <ForgotPasswordForm
                code={code}
                isInvalidCode={isInvalidCode}
                isInvalidPassword={isInvalidPassword}
                message={message}
                password={password}
                onCodeChange={handleCodeChange}
                onPasswordChange={handlePasswordChange}
                onResetPassword={handleResetPassword}
                onResetState={handleResetState}
              />
            )}
          </>
        ) : (
          <>
            <p>Enter your email and we'll send you a verification code.</p>
            <FormRow>
              <Col smColumns={12}>
                <form noValidate onSubmit={handleOnSubmit}>
                  <Input
                    extraClasses={
                      isInvalidEmail ? `is-invalid ${styles['is-invalid']}` : ''
                    }
                    handleChange={handleEmailChange}
                    handleKeyDown={handleEmailChange}
                    id="email"
                    initValue={email}
                    label="Email"
                    name="email"
                    type="email"
                  />
                  {isInvalidEmail && (
                    <span className={DEFAULT_ERRORS_CLASSNAME}>
                      Invalid email format
                    </span>
                  )}
                  <div className="d-md-flex justify-content-between align-items-center">
                    <div>
                      <NavLink aria-expanded="false" to="/welcome/back" exact>
                        Back to login
                      </NavLink>
                    </div>
                    <Button>Send reset code</Button>
                  </div>
                </form>
              </Col>
            </FormRow>
          </>
        )}
      </div>
    </>
  );
};

ForgotPassword.displayName = 'ForgotPassword';

export default ForgotPassword;
export { initialState, reducer };
