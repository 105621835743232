import React, { useReducer, useState } from 'react';
import PropTypes from 'prop-types';
import { H5, Icon, Caption } from '@thinkific/toga-react';
import Select from '@thinkific/toga-select';
import { reducer } from '../AppDetail/reducer';
import {
  DEVELOPERS_DOCUMENTATION_BASE_URL,
  NO_ACCESS,
  READ_ONLY,
  FULL_ACCESS,
  SELECT_SCOPE_HEADER,
} from './constants';
import styles from './AvailableScope.module.scss';

const AvailableScope = ({
  appData,
  dispatch,
  isLastScope,
  sameAsNextScopeValue,
  scope,
}) => {
  const resource = scope.value.split(':')[1];
  const docLinkReference = scope.slug;
  const [state] = useReducer(reducer, appData);
  const [selectedOption, setSelectedOption] = useState(NO_ACCESS);

  const dispatchScope = (value) => {
    const { scopes } = state;
    const existingScopeIndex = scopes.findIndex(
      (appScope) => appScope && appScope.split(':')[1] === resource
    );

    if (existingScopeIndex < 0) {
      state.scopes = [...scopes, value];
    } else {
      scopes[existingScopeIndex] = value;
      state.scopes = scopes;
    }

    state.scopes = state.scopes.filter((el) => el !== null && el !== '');

    if (value !== null) {
      setSelectedOption(selectedLabel());
    } else {
      setSelectedOption(NO_ACCESS);
    }

    dispatch({
      key: 'scopes',
      value: state.scopes,
    });
  };

  const isReadOnly = (scopeValue) => {
    return scopeValue.split(':')[0] === 'read';
  };

  const scopeOptions = () => {
    let options = [
      { text: NO_ACCESS, value: '', group: SELECT_SCOPE_HEADER },
      {
        text: READ_ONLY,
        value: `read:${resource}`,
        group: SELECT_SCOPE_HEADER,
      },
      {
        text: FULL_ACCESS,
        value: `write:${resource}`,
        group: SELECT_SCOPE_HEADER,
      },
    ];

    if (appData) {
      options = setOptions(scope.value);
    }

    return options;
  };

  const selectedLabel = () => {
    let label;

    if (state) {
      const { scopes } = state;
      const foundScopes = scopes.filter(
        (foundScope) => foundScope && foundScope.split(':')[1] === resource
      );

      if (foundScopes.length > 0) {
        const readOnlyScope = foundScopes.find((foundScope) =>
          isReadOnly(foundScope)
        );
        if (readOnlyScope) {
          label = READ_ONLY;
        } else {
          label = FULL_ACCESS;
        }
      }
    }

    return label;
  };

  const setOptions = (scopeValue) => {
    let options = [
      { text: NO_ACCESS, value: null, group: SELECT_SCOPE_HEADER },
      {
        text: READ_ONLY,
        value: `read:${resource}`,
        group: SELECT_SCOPE_HEADER,
      },
      {
        text: FULL_ACCESS,
        value: `write:${resource}`,
        group: SELECT_SCOPE_HEADER,
      },
    ];
    const readOnly = isReadOnly(scopeValue);
    const writeOnly = resource === 'external_orders';

    if (readOnly) {
      options = [
        { text: NO_ACCESS, value: null, group: SELECT_SCOPE_HEADER },
        {
          text: READ_ONLY,
          value: `read:${resource}`,
          group: SELECT_SCOPE_HEADER,
        },
      ];
    }
    if (writeOnly) {
      options = [
        { text: NO_ACCESS, value: null, group: SELECT_SCOPE_HEADER },
        {
          text: FULL_ACCESS,
          value: `write:${resource}`,
          group: SELECT_SCOPE_HEADER,
        },
      ];
    }
    return options;
  };

  const buildDocUrl = () => {
    return `${DEVELOPERS_DOCUMENTATION_BASE_URL}#${docLinkReference}`;
  };

  return (
    <div
      className={
        isLastScope ? styles.lastScopeContainer : styles.scopeContainer
      }
      key={scope.value}
      style={{ display: sameAsNextScopeValue ? 'none' : 'flex' }}
    >
      <div className={styles.scopeInfo}>
        <H5>{scope.title}</H5>
        <Caption>{scope.description}</Caption>
        <a className={styles.scopeDocLink} href={buildDocUrl()} target="_blank">
          View endpoints
          <Icon name="new-window" aria-hidden />
        </a>
      </div>
      <div className={styles.selectScope}>
        <Select
          dimensions={{
            menu: {
              maxWidth: 320,
              width: 210,
            },
            toggle: {
              maxWidth: 200,
            },
          }}
          id={resource}
          menuPosition="right"
          options={scopeOptions()}
          selectLabel={selectedLabel() || selectedOption}
          onSelect={(value) => {
            dispatchScope(value);
          }}
        />
      </div>
    </div>
  );
};

AvailableScope.propTypes = {
  appData: PropTypes.shape({
    scopes: PropTypes.array,
  }),
  dispatch: PropTypes.func,
  isLastScope: PropTypes.bool,
  sameAsNextScopeValue: PropTypes.bool,
  scope: PropTypes.shape({
    description: PropTypes.string,
    slug: PropTypes.string,
    title: PropTypes.string,
    value: PropTypes.string,
  }),
};

AvailableScope.defaultProps = {
  appData: { scopes: [] },
  dispatch: null,
  isLastScope: false,
  sameAsNextScopeValue: false,
  scope: { description: '', title: '', value: '' },
};

export default AvailableScope;
