import React, { useContext } from 'react';

/**
 * Is important to keep the context in your on file and
 * add the default values. This avoid unecessary mocks and erros
 */
const UserContext = React.createContext({
  onSignIn: () => {},
  onSignUp: () => {},
  onSignOut: () => {},
  onVerifyEmail: () => {},
  sendEmailVerificationCode: () => {},
  onUpdateUser: () => {},
  changePassword: () => {},
  user: {},
});

const useUserContext = () => useContext(UserContext);
export { UserContext, useUserContext };
