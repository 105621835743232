// TODO: Fix eslint without disables
/* eslint-disable jsx-a11y/anchor-is-valid */

import React from 'react';
import { NavLink } from 'react-router-dom';
import { useQuery } from '@apollo/react-hooks';
import TimeAgo from 'react-timeago';
import { CircularSpinner, Col, Row } from '@thinkific/toga-react';
import LatestNewQuery from './LatestNewQuery';
import ZendDeskSectionArticlesQuery from './ZendDeskSectionArticlesQuery';
import { KB_DASHBOARD } from './constants';
import styles from './LearnAndConnect.module.scss';
import { Card } from '../Card';
import { e2eId } from '../../utils/test.utils';

const LearnAndConnect = () => {
  const { data, loading } = useQuery(LatestNewQuery);
  const { data: sectionArticlesData } = useQuery(ZendDeskSectionArticlesQuery);
  const blogPost = data && data.blogPosts && data.blogPosts.items[0];
  const aboutArticles =
    sectionArticlesData &&
    sectionArticlesData.aboutArticles &&
    sectionArticlesData.aboutArticles.items;
  const developerResourceArticles =
    sectionArticlesData &&
    sectionArticlesData.developerResourceArticles &&
    sectionArticlesData.developerResourceArticles.items;
  const policyArticles =
    sectionArticlesData &&
    sectionArticlesData.policyArticles &&
    sectionArticlesData.policyArticles.items;
  const apiDocumentationArticles =
    sectionArticlesData &&
    sectionArticlesData.apiDocumentationArticles &&
    sectionArticlesData.apiDocumentationArticles.items;

  return (
    <div className={styles.container}>
      <Row>
        <Col smColumns={6}>
          <Card
            className="mb-4"
            headerAction={
              <a
                className="button button--knockout button--icon-right"
                data-cy="kb-home-link"
                href={KB_DASHBOARD}
                rel="noreferrer noopener"
                target="_blank"
              >
                Visit
                <span className="toga-icon toga-icon-arrow-up-right" />
              </a>
            }
            title="Knowledge Base"
          >
            <h5>About</h5>
            <ul>
              {aboutArticles &&
                sectionArticlesData.aboutArticles.items.map((aboutArticle) => (
                  <li key={aboutArticle.id}>
                    <a
                      href={aboutArticle.htmlUrl}
                      rel="noreferrer noopener"
                      target="_blank"
                    >
                      {aboutArticle.title}
                    </a>
                  </li>
                ))}
            </ul>
            <h5>Developer resources</h5>
            <ul>
              {developerResourceArticles &&
                sectionArticlesData.developerResourceArticles.items.map(
                  (developerResourceArticle) => (
                    <li key={developerResourceArticle.id}>
                      <a
                        href={developerResourceArticle.htmlUrl}
                        rel="noreferrer noopener"
                        target="_blank"
                      >
                        {developerResourceArticle.title}
                      </a>
                    </li>
                  )
                )}
            </ul>

            <h5>API documentation</h5>
            <ul>
              {apiDocumentationArticles &&
                sectionArticlesData.apiDocumentationArticles.items.map(
                  (apiDocumentationArticle) => (
                    <li key={apiDocumentationArticle.id}>
                      <a
                        href={apiDocumentationArticle.htmlUrl}
                        rel="noreferrer noopener"
                        target="_blank"
                      >
                        {apiDocumentationArticle.title}
                      </a>
                    </li>
                  )
                )}
            </ul>

            <h5>Policies and terms of service</h5>
            <ul className="mb-0">
              {policyArticles &&
                sectionArticlesData.policyArticles.items.map(
                  (policyArticle) => (
                    <li key={policyArticle.id}>
                      <a
                        href={policyArticle.htmlUrl}
                        rel="noreferrer noopener"
                        target="_blank"
                      >
                        {policyArticle.title}
                      </a>
                    </li>
                  )
                )}
            </ul>
          </Card>
        </Col>

        <Col smColumns={6}>
          <Card
            className="mb-4"
            headerAction={
              <NavLink
                aria-expanded="false"
                className="button button--knockout button--icon-right"
                to="/news"
                exact
              >
                VIEW ALL
                <i className="toga-icon toga-icon-arrow-right" />
              </NavLink>
            }
            title="News"
          >
            {loading && (
              <div className={styles.spinner}>
                <CircularSpinner variation="xlarge" />
              </div>
            )}
            {blogPost && (
              <>
                <h4>
                  <NavLink
                    aria-expanded="false"
                    to={`/news/${blogPost.slug}`}
                    exact
                  >
                    {blogPost.title}
                  </NavLink>
                </h4>

                <p className="mt-2 mb-3" {...e2eId('blog-post-title')}>
                  <TimeAgo date={blogPost.publishedAt} />
                </p>
                <NavLink
                  aria-expanded="false"
                  to={`/news/${blogPost.slug}`}
                  exact
                >
                  <img alt={blogPost.slug} src={blogPost.heroImage} />
                </NavLink>
              </>
            )}
          </Card>
        </Col>
      </Row>
    </div>
  );
};

LearnAndConnect.displayName = 'LearnAndConnect';

export default LearnAndConnect;
