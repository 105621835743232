import React, { useState, useReducer } from 'react';
import Button from '@thinkific/toga-button';
import Input from '@thinkific/toga-input';
import { Card } from '../../Card';
import styles from '../Account.module.scss';
import AccountActions from '../AccountActions';
import validations, { cognitoErrors } from './validations';
import { useUiContext } from '../../UiContext';
import {
  validateFields,
  DEFAULT_ERRORS_CLASSNAME,
  isValid,
} from '../../../utils/validations';
import { useUserContext } from '../../UserProvider';
import { PASSWORD_CAPTION } from '../../../utils/contants';

const initialState = {
  password: '',
  newPassword: '',
  confirmNewPassword: '',
};

const defaultError = {
  password: {
    invalid: 'Sorry, there was an error processing your request.',
  },
};

const reducer = (state, action) => {
  const { key, value } = action;
  if (key === 'reset') {
    return {
      ...state,
      ...value,
    };
  }
  return {
    ...state,
    [key]: value,
  };
};

const ChangePassword = () => {
  const { changePassword } = useUserContext();
  const { showToast } = useUiContext();
  const [formVisible, setFormVisible] = useState(false);
  const [state, dispatch] = useReducer(reducer, initialState);
  const { password, newPassword, confirmNewPassword } = state;
  const [saving, setSaving] = useState(false);
  const [validationErrors, setvalidationErrors] = useState(initialState);

  const checkForErrors = () => {
    const lastErrors = validateFields(state, validations);
    setvalidationErrors(lastErrors);
    return lastErrors;
  };

  // eslint-disable-next-line consistent-return
  const onSubmit = (e) => {
    e.preventDefault();
    setSaving(true);

    const lastErrors = checkForErrors();

    if (!isValid(lastErrors)) {
      return setSaving(false);
    }

    // eslint-disable-next-line consistent-return
    changePassword({ password, newPassword }, (err) => {
      setSaving(false);
      if (err) {
        const { code } = err;
        const message = cognitoErrors[code] || defaultError;
        return setvalidationErrors(message);
      }
      setFormVisible(false);
      dispatch({ key: 'reset', value: initialState });
      showToast('notice', 'Password saved successfully');
    });
  };

  return (
    <Card title="Password">
      {!formVisible ? (
        <Button
          appearance="secondary"
          data-cy="changePasswordbutton"
          type="button"
          onClick={() => setFormVisible(true)}
        >
          Change password
        </Button>
      ) : (
        <form
          className={styles.enter}
          id="changePasswordForm"
          onSubmit={onSubmit}
        >
          <div className={`form-row ${styles.form}`}>
            <div className="col-md-12">
              <Input
                extraProps={{
                  disabled: saving,
                  onBlur: () => checkForErrors(),
                }}
                handleChange={(e) =>
                  dispatch({ key: 'password', value: e.target.value })
                }
                id="password"
                initValue={password}
                label="Current password"
                name="password"
                placeholder=""
                type="password"
              />
              {validationErrors.password &&
                validationErrors.password.required && (
                  <span className={DEFAULT_ERRORS_CLASSNAME}>
                    {validationErrors.password.required}
                  </span>
                )}
              {validationErrors.password &&
                validationErrors.password.invalid && (
                  <span className={DEFAULT_ERRORS_CLASSNAME}>
                    {validationErrors.password.invalid}
                  </span>
                )}
            </div>
            <div className="col-md-12">
              <Input
                extraProps={{
                  disabled: saving,
                  onBlur: () => checkForErrors(),
                }}
                formText={!validationErrors.newPassword ? PASSWORD_CAPTION : ''}
                handleChange={(e) =>
                  dispatch({ key: 'newPassword', value: e.target.value })
                }
                id="newpassword"
                initValue={newPassword}
                label="New password"
                name="newPassword"
                placeholder=""
                type="password"
              />
              {validationErrors.newPassword && (
                <span className={DEFAULT_ERRORS_CLASSNAME}>
                  {PASSWORD_CAPTION}
                </span>
              )}
            </div>
            <div className="col-md-12">
              <Input
                extraProps={{
                  disabled: saving,
                  onBlur: () => checkForErrors(),
                }}
                handleChange={(e) =>
                  dispatch({ key: 'confirmNewPassword', value: e.target.value })
                }
                id="confirmpassword"
                initValue={confirmNewPassword}
                label="Confirm password"
                name="confirmpassword"
                placeholder=""
                type="password"
              />
              {validationErrors.confirmNewPassword &&
                validationErrors.confirmNewPassword.match && (
                  <span className={DEFAULT_ERRORS_CLASSNAME}>
                    {validationErrors.confirmNewPassword.match}
                  </span>
                )}
            </div>
          </div>
          <AccountActions
            saveLabel="Save Password"
            saving={saving}
            onCancel={() => {
              setFormVisible(false);
              setvalidationErrors({});
              dispatch({ key: 'reset', value: initialState });
            }}
          />
        </form>
      )}
    </Card>
  );
};

export default ChangePassword;
