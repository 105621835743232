import gql from 'graphql-tag';

const AppScopesMutation = gql`
  mutation UpdateAppScopes($id: ID!, $scopes: [String]) {
    updateAppScopes(id: $id, scopes: $scopes) {
      scopes
    }
  }
`;

export default AppScopesMutation;
