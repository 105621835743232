import gql from 'graphql-tag';

const CreateDemoSiteMutation = gql`
  mutation CreateDemoSite {
    createDemoSite {
      tenant {
        id
        subdomain
      }
    }
  }
`;

export default CreateDemoSiteMutation;
