/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */

import React, { useEffect } from 'react';
import { Route, Switch } from 'react-router-dom';
import { Button, MobileTitleBar } from '@thinkific/toga-react';
import { ToastContainer } from '@thinkific/toga-toast';
import Main from '@thinkific/toga-main';
import { useUserContext } from '../UserProvider';
import Sidebar from '../Sidebar';
import Header from '../Header';
import LearnAndConnect from '../LearnAndConnect';
import NewsDashboard from '../News';
import NewsDetail from '../News/NewsDetail';
import { DevelopmentSites, ManagedSites } from '../Sites';
import Apps from '../Apps';
import Account from '../Account';
import Callback from '../Callback';
import ZendeskWidget from '../ZendeskWidget';
import styles from './Portal.module.scss';
import { useUiContext } from '../UiContext';
import { Modal } from '../Modal';
import { TOAST_TIME } from './constants';
import {
  indexPath,
  newsPath,
  newsViewPath,
  sitesPath,
  sitesManagedPath,
  sitesDevelopmentPath,
  appsPath,
  accountPath,
  oauthCallbackPath,
  roadmapPath,
} from '../../utils/routes';
import Banner from '../Banner';
import { useFlags } from '../../utils/flags';
import { EmailValidationAlert } from '../EmailValidationAlert';
import Roadmap from '../Roadmap';

const Portal = () => {
  const { user } = useUserContext();
  const {
    modalInstance,
    pageProps: { hasBackground, singleColumn },
    toggleSidebar,
  } = useUiContext();

  const flags = useFlags();

  useEffect(() => {
    if (user && window.Appcues) {
      window.Appcues.identify(user.sub, {
        userEmail: user.email,
        firstName: user.givenName,
        lastName: user.familyName,
      });
    }
  }, [user]);

  return (
    <>
      {flags.featureDowntimeBanner && (
        <Banner
          rightAccessory={
            <Button
              appearance="primary"
              href="https://status.thinkific.com"
              target="_blank"
            >
              Learn More
            </Button>
          }
          type="warning"
        >
          <b>
            Some services are currently unavailable due to some routine
            maintenance we&apos;re performing to improve our systems.
          </b>
        </Banner>
      )}
      <div
        className={`${
          flags.featureDowntimeBanner
            ? styles['conditional-portal-wrapper']
            : ''
        }`}
      >
        {modalInstance && <Modal />}
        {user && !user.emailVerified && <EmailValidationAlert />}
        <MobileTitleBar
          primaryAction={toggleSidebar}
          title="Partner Portal"
          wrapperClass={
            user && !user.emailVerified ? styles['pushed-down'] : ''
          }
        />
        {/* TODO: Find a way to change to toast color
         * or make a PR for toga changing what we need to
         * make the toast working as expected
         */}
        <ToastContainer autoClose={TOAST_TIME} />
        <Sidebar shrunk={user && !user.emailVerified} />
        <div
          className={`${styles.bg} ${hasBackground ? `` : styles['bg--none']}`}
        />
        <Main>
          <Header />
          <div
            className={`${styles.content__container} ${
              singleColumn ? styles['content__container--single-column'] : ''
            }`}
          >
            <Switch>
              <Route component={LearnAndConnect} path={indexPath()} exact />
              <Route component={NewsDashboard} path={newsPath()} exact />
              <Route component={NewsDetail} path={newsViewPath()} exact />
              <Route component={DevelopmentSites} path={sitesPath()} exact />
              <Route component={ManagedSites} path={sitesManagedPath()} exact />
              <Route
                component={DevelopmentSites}
                path={sitesDevelopmentPath()}
                exact
              />
              <Route component={Apps} path={appsPath()} />
              {flags.featurePartnerPortalRoadmap && (
                <Route component={Roadmap} path={roadmapPath()} exact />
              )}
              <Route component={Account} path={accountPath()} exact />
              <Route component={Callback} path={oauthCallbackPath()} exact />
            </Switch>
          </div>
        </Main>
        <ZendeskWidget />
      </div>
    </>
  );
};

Portal.displayName = 'Portal';

export default Portal;
