import { createContext, useContext } from 'react';
import ROUTES from '../../utils/routeConstants';

const UiContext = createContext({
  launchModal: () => {},
  closeModal: () => {},
  changePageProps: () => {},
  showToast: () => {},
  toggleSidebar: () => {},
  pageProps: {
    singleColumn: false,
    breadCrumbLabel: '',
    breadCrumbPath: '/',
    hasAction: false,
    hasBackground: true,
    hasBreadcrumb: false,
    hasTitle: true,
    href: '',
    title: ROUTES.INDEX.TITLE,
  },
  modalInstance: null,
  toastInstance: null,
  isSidebarOpen: false,
  windowWidth: 0,
  updateWindowDimensions: () => {},
});

const useUiContext = () => useContext(UiContext);
export { UiContext, useUiContext };
