/* eslint-disable react/no-unescaped-entities */
import React from 'react';
import InlineAlert from '@thinkific/toga-inline-alert';
import Input from '@thinkific/toga-input';
import Button from '@thinkific/toga-button';
import { Col } from '@thinkific/toga-grid';
import PropTypes from 'prop-types';
import { Card } from '../../Card';
import styles from './Install.module.scss';

const Install = ({ allowInstall, value, onChange, onInstall }) => {
  const rightAccessory = <span>.thinkific.com</span>;

  return (
    <Card title="Install">
      {!allowInstall && (
        <InlineAlert className={styles.install__alert} type="info">
          <span>
            To install your app on Thinkific sites, finish filling your app's
            details above.
          </span>
        </InlineAlert>
      )}
      <div className={styles.container}>
        <Col className={styles.input__wrapper}>
          <p>
            Install button uses authorization code flow with PKCE, so please
            when using it, make sure to update your App's Callback URL list with
            the following:
            <b>
              {` ${window.location.protocol}//${window.location.host}/oauth/callback`}
            </b>
          </p>
          <br />
          <Input
            extraProps={{ disabled: !allowInstall }}
            handleChange={(e) => onChange(e.target.value)}
            id="subdomain"
            initValue={value}
            label="Thinkific Site URL"
            name="subdomain"
            placeholder="subdomain"
            rightAccessory={rightAccessory}
          />
          <Button
            appearance="primary"
            data-cy="installAppButton"
            disabled={!allowInstall || !value}
            type="button"
            onClick={() => {
              onInstall();
            }}
          >
            Install
          </Button>
        </Col>
      </div>
    </Card>
  );
};

Install.propTypes = {
  allowInstall: PropTypes.bool.isRequired,
  value: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  onInstall: PropTypes.func.isRequired,
};

export default Install;
