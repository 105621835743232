import React, { useReducer, useState } from 'react';
import { useQuery, useMutation } from '@apollo/react-hooks';
import {
  Button,
  CircularSpinner,
  Input,
  Label,
  Section,
  SectionHeader,
} from '@thinkific/toga-react';
import { ModalHeader, ModalFooter, ModalBody } from '@thinkific/toga-modal';
import ReactModal from 'react-modal';
import { useParams } from 'react-router-dom';
import PropTypes from 'prop-types';
import { graphqlMutationErrors } from '../../../utils/errors';
import { AvailableScopesQuery, AppScopesMutation } from './graphql';
import { useUiContext } from '../../UiContext';
import { reducer } from '../AppDetail/reducer';
import AvailableScope from './AvailableScope';
import Icons from '../../Icons';
import styles from './AvailableScope.module.scss';
import { DEVELOPERS_DOCUMENTATION_BASE_URL } from './constants';
import { useFlags } from '../../../utils/flags';

const AvailableScopes = ({ appData, dispatch }) => {
  const { data, loading } = useQuery(AvailableScopesQuery);
  // Temporary modal removal - it should be uncommented soon
  // eslint-disable-next-line no-unused-vars
  const [displayModal, setDisplayModal] = useState(false);
  const [disabledSave, setDisabledSave] = useState(false);
  const [state] = useReducer(reducer, appData);
  const { showToast } = useUiContext();
  const { id } = useParams();
  const flags = useFlags();
  const scopesData = data && data.availableScopes && data.availableScopes.items;
  const graphQlcoursePlayerScopes = [
    'read:course_progress',
    'read:lessons',
    'write:course_progress',
  ];
  const thinkificPaymentScopes = [
    'read:balance_transactions'
  ];
  const hiddenPublicScopes = [
    'read:account',
    'read:assignment_submissions',
    'write:assignment_submissions',
    'read:quiz_submissions',
    'read:survey_submissions'
  ];

  const isScopeAllowed = (currentScope) => {
    // Allow all scopes except the ones in graphQlcoursePlayerScopes, thinkificPaymentScopes, hiddenPublicScopes
    let allowed = true;

    if (graphQlcoursePlayerScopes.includes(currentScope.value)) {
      allowed = flags.featureGraphQlCoursePlayerScope; // Scope is rendered if flag is true
    } else if (thinkificPaymentScopes.includes(currentScope.value)) {
      allowed = flags.featureGraphQlPaymentScopes; // Scope is rendered if flag is true
    } else if (hiddenPublicScopes.includes(currentScope.value)) {
      allowed = !flags.featureGraphQlHiddenPublicScopes; // Scope is hidden if flag is true
    }

    return allowed;
  };

  const sameAsNextScope = (currentScope) => {
    const currentScopeIndex = scopesData.indexOf(currentScope);

    if (isLastScope(currentScope)) {
      return false;
    }

    return scopesData[currentScopeIndex + 1].title === currentScope.title;
  };

  const isLastScope = (currentScope) => {
    const currentScopeIndex = scopesData.indexOf(currentScope);

    return currentScopeIndex + 1 >= scopesData.length;
  };

  const { scopes } = state;

  const [updateAppScopes] = useMutation(AppScopesMutation, {
    variables: { id, scopes },
  });

  const hideModal = () => {
    setDisplayModal(false);
  };

  // Temporary modal removal - it should be uncommented soon
  // eslint-disable-next-line no-unused-vars
  const openConfirmModal = () => {
    setDisplayModal(true);
  };

  const onUpdateSuccess = (msg) => {
    showToast('notice', msg);
  };

  // eslint-disable-next-line consistent-return
  const onSubmit = async (event) => {
    event.preventDefault();
    setDisabledSave(true);

    try {
      const response = await updateAppScopes();
      if (response.data) {
        hideModal();
        setDisabledSave(false);
        onUpdateSuccess('Scopes saved successfully');
      }
    } catch (e) {
      hideModal();
      showToast('alert', graphqlMutationErrors(e));
    }
  };

  // Temporary modal removal - it should be uncommented soon
  // eslint-disable-next-line no-unused-vars
  const ConfirmScopesModal = () => {
    // Temporary modal removal - it should be uncommented soon
    // const [disabledSave, setDisabledSave] = useState(true);
    const [confirmDialogText, setConfirmDialogText] = useState('');
    const confirmDialogInput = (inputValue) => {
      setConfirmDialogText(inputValue);

      if (inputValue === 'CONFIRM') {
        setDisabledSave(false);
      } else {
        setDisabledSave(true);
      }
    };

    return (
      <form onSubmit={(e) => onSubmit(e)}>
        <ReactModal
          ariaHideApp={false}
          className={styles.modal__container}
          contentLabel="Modal"
          overlayClassName={styles.overlayModal}
          isOpen
          onRequestClose={hideModal}
        >
          <ModalHeader className={styles.modalHeader}>
            <h4>Scope changes will affect users</h4>
            <Button
              appearance="utility"
              aria-label="Close"
              className="modal__header__close-button"
              onClick={hideModal}
            >
              <Icons.Dismiss />
            </Button>
          </ModalHeader>
          <ModalBody className={styles.modalBody}>
            <div>
              <h5>Adding new scopes</h5>
              <p>
                Saving these changes will redirect existing app users to accept
                new permissions via the app consent page upon their next app
                visit. We recommend you communicate new features via your
                current marketing channels.
              </p>
              <br />
              <h5>Removing scopes</h5>
              <p>
                This will immediately remove the scopes from existing app users.
              </p>
              <br />
              <Label htmlFor="appIcon">
                Type &quot;CONFIRM&quot; to continue
              </Label>
              <Input
                className={styles.modalInput}
                handleChange={(e) => confirmDialogInput(e.target.value)}
                id="confirmDialogInput"
                initValue={confirmDialogText}
                name="confirmDialogInput"
              />
            </div>
          </ModalBody>
          <ModalFooter className={styles.modalFooter}>
            <Button appearance="secondary" onClick={() => hideModal()}>
              Cancel
            </Button>
            <Button
              data-cy="saveScopesDialogButton"
              disabled={disabledSave}
              type="button"
              onClick={(e) => onSubmit(e, 'Scopes saved successfully')}
            >
              Save
            </Button>
          </ModalFooter>
        </ReactModal>
      </form>
    );
  };

  return (
    <form onSubmit={(e) => onSubmit(e)}>
      <Section>
        <SectionHeader
          rightAccessory={[
            <Button
              appearance="primary"
              data-cy="saveScopesButton"
              disabled={disabledSave}
              key="saveScopes"
              type="submit"
              onClick={(e) => onSubmit(e, 'Scopes saved successfully')}
            >
              Save
            </Button>,
          ]}
          title="OAuth scopes"
        />
        <p>
          Your apps functionality and capabilities are defined by the scopes
          requested.&nbsp;
          <a
            className={styles['scopes--anchor']}
            href={DEVELOPERS_DOCUMENTATION_BASE_URL}
            target="_blank"
          >
            Learn more
          </a>
        </p>
        {loading && <CircularSpinner variation="xlarge" />}
        <h5 className={styles.selectScopes}>Select scopes access</h5>
        <div className={styles.availableScopes}>
          {appData &&
            scopesData &&
            scopesData
              .filter((s) => !sameAsNextScope(s))
              .map(
                (scope) =>
                  isScopeAllowed(scope) && (
                    <AvailableScope
                      appData={appData}
                      dispatch={dispatch}
                      isLastScope={isLastScope(scope)}
                      key={scope.value}
                      sameAsNextScopeValue={sameAsNextScope(scope)}
                      scope={scope}
                    />
                  )
              )}
        </div>
      </Section>
      {/* {displayModal && <ConfirmScopesModal />} */}
    </form>
  );
};

AvailableScopes.propTypes = {
  appData: PropTypes.shape({
    scopes: PropTypes.array,
  }),
  dispatch: PropTypes.func,
};

AvailableScopes.defaultProps = {
  appData: { scopes: [] },
  dispatch: null,
};

export default AvailableScopes;
