import React from 'react';
import PropTypes from 'prop-types';
import { CreateDemoSiteMutation, TenantsQuery } from './graphql';
import DemoSiteCard from './DemoSiteCard';
import { useUiContext } from '../../UiContext';
import withQuery from '../../../hocs/withQuery';
import withMutation from '../../../hocs/withMutation';
import checkSiteDomain from '../../../utils/checkSiteDomain';
import { graphqlMutationErrors } from '../../../utils/errors';
import thinkific from '../../../config/thinkific';

const DevelopmentSites = ({ query, mutation }) => {
  const { showToast } = useUiContext();
  const { data: queryResult, loading: queryLoading } = query;
  const {
    action,
    data: { data: newTenantData, loading: newTenantLoading },
  } = mutation;

  const tenant = newTenantData ? newTenantData.createDemoSite.tenant : null;
  const tenants = queryResult ? queryResult.tenants : null;
  const { hasDemoSite, subdomain, tenantId } = checkSiteDomain(tenant, tenants);
  const loading = queryLoading || newTenantLoading;
  const siteUrl = `https://${subdomain}.${thinkific.domain}`;

  async function handleRequest() {
    try {
      await action();
    } catch (e) {
      showToast('alert', graphqlMutationErrors(e));
    }
  }

  return (
    <>
      <div className="row">
        <div className="col">
          <DemoSiteCard
            createDemoSite={handleRequest}
            hasDemoSite={hasDemoSite}
            loading={loading}
            siteUrl={siteUrl}
            tenantId={tenantId}
          />
        </div>
      </div>
    </>
  );
};

DevelopmentSites.defaultProps = {
  query: {
    data: {},
    loading: false,
  },
  mutation: {
    action: () => {},
    data: {
      loading: false,
    },
  },
};

DevelopmentSites.propTypes = {
  mutation: PropTypes.shape({
    action: PropTypes.func,
    data: PropTypes.object,
  }),
  query: PropTypes.shape({
    data: PropTypes.object,
    loading: PropTypes.bool,
  }),
};

DevelopmentSites.displayName = 'DevelopmentSites';

export { DevelopmentSites };
export default withMutation(
  withQuery(DevelopmentSites, TenantsQuery),
  CreateDemoSiteMutation
);
