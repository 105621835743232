import React from 'react';
import PropTypes from 'prop-types';
import Surface from '@thinkific/toga-surface';
import Icons from '../../Icons';
import styles from './EmptyAppCard.module.scss';

const EmptyAppCard = ({ label, onClick, ...props }) => {
  return (
    <div
      className={styles.container}
      role="button"
      tabIndex="0"
      onClick={onClick}
      onKeyDown={onClick}
      {...props}
    >
      <Surface className={styles.surface}>
        <Icons.Plus className={styles.icon} />
        <h5 className={styles.label}>{label}</h5>
      </Surface>
    </div>
  );
};

EmptyAppCard.propTypes = {
  label: PropTypes.string.isRequired,
  onClick: PropTypes.func,
};

EmptyAppCard.defaultProps = {
  onClick: () => {},
};

export default EmptyAppCard;
