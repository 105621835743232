import React, { useReducer, useLayoutEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { Input, Notification } from '@thinkific/toga-react';
import { Card } from '../Card';
import { useUserContext } from '../UserProvider';
import { useUiContext } from '../UiContext';
import reducer, { MERGE, DISCARD } from './store';
import styles from './Account.module.scss';
import {
  validateFields,
  DEFAULT_ERRORS_CLASSNAME,
  isValid,
} from '../../utils/validations';
import validations from './validations';
import ChangePassword from './ChangePassword';
import AccountActions from './AccountActions';

const initialState = {
  givenName: '',
  familyName: '',
  email: '',
  phoneNumber: '',
  website: '',
  modified: false,
  snapshot: {},
};

const Account = () => {
  const history = useHistory();
  const { user, onUpdateUser } = useUserContext();
  const { showToast } = useUiContext();
  const [state, dispatch] = useReducer(reducer, initialState);
  const [saving, setSaving] = useState(false);
  const [validationErrors, setValidationErrors] = useState({});
  const { givenName, familyName, email, phoneNumber, website, modified } =
    state;

  /**
   * TODO: Maybe find a better way
   * useEffect was not used here because
   * it is not possible to mutate an state inside of it
   * and useLayoutEffect is trigged only once
   */
  useLayoutEffect(() => {
    if (user) {
      const { phoneNumberVerified, emailVerified, sub, ...rest } = user;
      dispatch({ key: MERGE, value: rest });
    }
    return () => {};
  }, [user]);

  const onUpdateSuccess = (err, success) => {
    setSaving(false);

    if (err) {
      console.log(err);
    }

    if (success === 'SUCCESS') {
      showToast('notice', 'Personal information changes saved successfully');
      dispatch({ key: MERGE, value: state });
    }

    if (user.email !== state.email) {
      history.go(0);
    }
  };

  const onSubmit = (e) => {
    e.preventDefault();

    const { website: actualWebsite, ...rest } = state;
    const lastErrors = validateFields(
      actualWebsite !== '' ? state : rest,
      validations
    );
    setValidationErrors(lastErrors);

    if (isValid(lastErrors)) {
      const { snapshot, modified: isModified, ...updateAccount } = state;
      setSaving(true);
      onUpdateUser(updateAccount, onUpdateSuccess);
    }
  };

  const onlyNumbers = (value) => {
    const regex = /([0-9])/gi;
    const numbers = value.match(regex) ? value.match(regex).join('') : '';
    const newValue = `+${numbers}`;
    dispatch({ key: 'phoneNumber', value: newValue });
  };

  return (
    <>
      <div className="row">
        <div className="col">
          <form id="accountForm" onSubmit={onSubmit}>
            <Card title="Personal information">
              <div className="form-row">
                <div className="col-md-6">
                  <Input
                    extraProps={{ disabled: saving }}
                    handleChange={(e) =>
                      dispatch({
                        key: 'givenName',
                        value: e.target.value.trim(),
                      })
                    }
                    id="givenName"
                    initValue={givenName}
                    label="First Name"
                    name="givenName"
                    placeholder="Last Name"
                    type="text"
                  />
                  {validationErrors.givenName &&
                    validationErrors.givenName.required &&
                    modified && (
                      <span className={DEFAULT_ERRORS_CLASSNAME}>
                        {validationErrors.givenName.required}
                      </span>
                    )}
                </div>

                <div className="col-md-6">
                  <Input
                    extraProps={{ disabled: saving }}
                    handleChange={(e) =>
                      dispatch({
                        key: 'familyName',
                        value: e.target.value.trim(),
                      })
                    }
                    id="familyName"
                    initValue={familyName}
                    label="Last Name"
                    name="familyName"
                    placeholder="Last Name"
                    type="text"
                  />
                  {validationErrors.familyName &&
                    validationErrors.familyName.required &&
                    modified && (
                      <span className={DEFAULT_ERRORS_CLASSNAME}>
                        {validationErrors.familyName.required}
                      </span>
                    )}
                </div>
              </div>
              <div className="form-row">
                <div className="col-md-12">
                  <Input
                    handleChange={(e) =>
                      dispatch({ key: 'email', value: e.target.value })
                    }
                    id="email"
                    initValue={email}
                    label="Email"
                    name="email"
                    placeholder="name@company.com"
                    type="text"
                  />
                  {validationErrors.email &&
                    validationErrors.email.email &&
                    modified && (
                      <span className={DEFAULT_ERRORS_CLASSNAME}>
                        {validationErrors.email.email}
                      </span>
                    )}
                </div>
                <div className="col-md-12">
                  <Notification className="my-3" type="warning">
                    To keep your account secure and access all features, you’ll
                    need to verify your new email address by entering the
                    verification code sent to your email.
                  </Notification>
                </div>
              </div>
              <div className="form-row">
                <div className="col-md-12">
                  <Input
                    extraProps={{ disabled: saving }}
                    handleChange={(e) => onlyNumbers(e.target.value)}
                    id="phoneNumber"
                    initValue={phoneNumber}
                    label="Phone Number"
                    name="phoneNumber"
                    placeholder="778 222 3333"
                    type="tel"
                  />
                </div>
              </div>
              <div className="form-row">
                <div className="col-md-12">
                  <Input
                    extraProps={{ disabled: saving }}
                    handleChange={(e) =>
                      dispatch({ key: 'website', value: e.target.value })
                    }
                    id="website"
                    initValue={website}
                    label="Website"
                    name="website"
                    placeholder="Your Website"
                    type="text"
                  />
                  {validationErrors.website &&
                    validationErrors.website.urls &&
                    modified && (
                      <span className={DEFAULT_ERRORS_CLASSNAME}>
                        {validationErrors.website.urls}
                      </span>
                    )}
                </div>
              </div>
              {modified && (
                <AccountActions
                  cancelLabel="Discard"
                  className={styles.enter}
                  saveLabel="Save Changes"
                  saving={saving}
                  onCancel={() => dispatch({ key: DISCARD, value: null })}
                />
              )}
            </Card>
          </form>
          <ChangePassword />
        </div>
      </div>
    </>
  );
};

Account.displayName = 'Account';

export default Account;
