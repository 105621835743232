import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import Button from '@thinkific/toga-button';
import CircularSpinner from '@thinkific/toga-circular-spinner';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { SsoTenantMutation } from '../graphql';
import withMutation from '../../../../hocs/withMutation';
import { useUiContext } from '../../../UiContext';
import { graphqlMutationErrors } from '../../../../utils/errors';
import styles from './DemoSiteCardFooter.module.scss';

const DemoSiteCardFooter = ({ tenantId, siteUrl, mutation }) => {
  const {
    action: ssoTenant,
    data: { data, loading },
  } = mutation;
  const { showToast } = useUiContext();
  const [copied, setCopied] = useState(false);

  useEffect(() => {
    if (data) {
      window.open(data.ssoTenant.url, '_blank');
    }
  }, [data]);

  const copySiteUrl = () => {
    setCopied(true);
    setTimeout(() => setCopied(false), 2000);
  };

  async function handleRequest() {
    try {
      await ssoTenant({ variables: { tenantId } });
    } catch (e) {
      showToast('alert', graphqlMutationErrors(e));
    }
  }

  return (
    <footer className={styles.footer}>
      <CopyToClipboard text={siteUrl} onCopy={copySiteUrl}>
        <Button appearance="secondary" isActive={copied}>
          {copied && 'COPIED!'}
          {!copied && 'COPY URL'}
        </Button>
      </CopyToClipboard>
      <Button
        appearance="primary"
        className={styles.button}
        disabled={loading}
        onClick={handleRequest}
      >
        VISIT DASHBOARD
        {loading && <CircularSpinner className={styles.spinner} />}
        {!loading && <span className="toga-icon toga-icon-arrow-up-right" />}
      </Button>
    </footer>
  );
};

DemoSiteCardFooter.defaultProps = {
  mutation: {
    action: () => {},
    data: {
      loading: false,
    },
  },
};

DemoSiteCardFooter.propTypes = {
  mutation: PropTypes.shape({
    action: PropTypes.func,
    data: PropTypes.object,
  }),
  siteUrl: PropTypes.string.isRequired,
  tenantId: PropTypes.string.isRequired,
};

export { DemoSiteCardFooter };
export default withMutation(DemoSiteCardFooter, SsoTenantMutation);
