window.zESettings = {
  webWidget: {
    chat: {
      suppress: true,
    },
    contactForm: {
      suppress: false,
      title: {
        'en-US': 'How can we help?',
        '*': 'How can we help?',
      },
      tags: ['partner_portal'],
    },
    helpCenter: {
      suppress: true,
    },
    talk: {
      suppress: true,
    },
    answerBot: {
      suppress: true,
    },
  },
  launcher: {
    label: {
      'en-US': 'Partner Support',
      '*': 'Partner Support',
    },
  },
};
