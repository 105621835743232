/* eslint-disable no-undef */
const { REACT_APP_PP_RECAPTCHA_SITE_KEY, REACT_APP_HIDE_RECAPTCHA } =
  process.env;

const insertScript = () => {
  const script = document.createElement('script');
  script.src =
    'https://www.google.com/recaptcha/api.js?onload=onLoadRecaptcha&render=explicit';
  script.async = true;
  script.defer = true;
  script.id = 'recaptcha';
  document.head.appendChild(script);
};

// eslint-disable-next-line consistent-return
const render = (element, reload, callback) => {
  if (REACT_APP_HIDE_RECAPTCHA) {
    return callback();
  }
  if (!window.grecaptcha) {
    // eslint-disable-next-line consistent-return
    return;
  }
  window.grecaptcha.render(element, {
    sitekey: REACT_APP_PP_RECAPTCHA_SITE_KEY,
    callback,
  });
};

// eslint-disable-next-line consistent-return
const loadReacaptcha = (element, callback) => {
  const hasScript = document.getElementById('recaptcha');
  if (hasScript) {
    return render(element.current, true, callback);
  }
  window.onLoadRecaptcha = () => {
    render(element.current, false, callback);
  };
  insertScript();
};

export default loadReacaptcha;
