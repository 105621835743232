const createAppFormStore = (initialState = {}) => {
  let storage = {
    ...initialState,
  };
  return {
    setField: (name, value) => {
      storage[name] = value;
    },
    getField: (name) => {
      if (name in storage) {
        return storage[name];
      }
      return null;
    },
    clear: () => {
      storage = {
        ...initialState,
      };
    },
    getState: () => {
      return storage;
    },
  };
};

export { createAppFormStore };
