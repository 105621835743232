import React, { Children, useState } from 'react';
import PropTypes from 'prop-types';
import Divider from '@thinkific/toga-divider';
import { SidebarContext } from './SidebarContext';
import SidebarHeader from './SidebarHeader';
import SidebarFooter from './SidebarFooter';
import styles from './Sidebar.module.scss';

let typesAreEqual = (typeA, typeB) => typeA === typeB;

if (process.env.NODE_ENV !== 'production') {
  // workaround for proxies used by react-hot-loader
  typesAreEqual = (typeA, typeB) =>
    typeA === typeB || typeA.name === typeB.name;
}

const SidebarContainer = ({ shrunk, isOpen, children }) => {
  let header = null;
  let footer = null;
  const content = [];

  Children.forEach(children, (child) => {
    if (child && typesAreEqual(SidebarHeader, child.type)) {
      header = child;
    } else if (child && typesAreEqual(SidebarFooter, child.type)) {
      footer = child;
    } else {
      content.push(child);
    }
  });

  const [activeMenuItem, toggleMenuItem] = useState(null);
  const context = {
    toggleMenuItem,
    activeMenuItem,
  };

  return (
    <SidebarContext.Provider value={context}>
      <aside
        className={`sidebar ${styles.sidebar} ${
          isOpen ? styles['is-opened'] : ''
        }`}
      >
        <div
          className={`${styles.container} ${
            shrunk ? styles['container--shrunk'] : ''
          }`}
        >
          {header}
          <Divider />
          <div className="sidebar__container">
            <nav className="sidebar__navigation">{content}</nav>
            <Divider />
            {footer}
          </div>
        </div>
      </aside>
    </SidebarContext.Provider>
  );
};

SidebarContainer.defaultProps = {
  shrunk: false,
  isOpen: true,
};

SidebarContainer.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
  isOpen: PropTypes.bool,
  shrunk: PropTypes.bool,
};

SidebarContainer.displayName = 'SidebarContainer';

export default SidebarContainer;
