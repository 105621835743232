/* eslint-disable no-useless-escape */

const MESSAGES = {
  INFO: 'Verification code has been sent to your email to reset your password',
  SUCCESS: 'Password reset successfully',
};

const TITLES = {
  STEP_ONE: 'Forgot your password?',
  STEP_TWO: 'Reset password',
};

const TYPES = {
  ERROR: 'error',
  INFO: 'info',
  SUCCESS: 'success',
  WARNING: 'warning',
};

const EMAIL_REGEX =
  /^(([^<>()\[\]\\.,;:^&*#%~!$\s@"]+(\.[^<>()\[\]\\.,;:^&*#%~!$\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

const PASSWORD_REGEX = /(?=.*\d)(?=.*[A-Z])(?=.*[a-z])/;
const PASSWORD_LENGTH = 8;

export {
  EMAIL_REGEX,
  MESSAGES,
  PASSWORD_REGEX,
  PASSWORD_LENGTH,
  TITLES,
  TYPES,
};
