import React from 'react';
import Tooltip from '@thinkific/toga-tooltip';
import PropTypes from 'prop-types';
import styles from './LabelToolTip.module.scss';
import Icons from '../Icons';

/** Component created to avoid toga errors. Labels in toga do not support elements */
const LabelToolTip = ({ label, tipText, containerClass }) => (
  <div className={containerClass && styles[containerClass]}>
    <span>{label}</span>
    <Tooltip
      className={styles['custom-tooltip']}
      innerClassName={styles['custom-tooltip-inner']}
      title={tipText}
    >
      <Icons.CircleOutlineInfo />
    </Tooltip>
  </div>
);

LabelToolTip.propTypes = {
  containerClass: PropTypes.string,
  label: PropTypes.string.isRequired,
  tipText: PropTypes.string.isRequired,
};

LabelToolTip.defaultProps = {
  containerClass: 'tip-container',
};

export default LabelToolTip;
