import gql from 'graphql-tag';

const SsoTenantMutation = gql`
  mutation SsoTenant($tenantId: String!) {
    ssoTenant(tenantId: $tenantId) {
      url
    }
  }
`;

export default SsoTenantMutation;
