import gql from 'graphql-tag';

const AvailableScopesQuery = gql`
  query AvailableScopes {
    availableScopes {
      items {
        title
        description
        value
        slug
      }
    }
  }
`;

export default AvailableScopesQuery;
