import React from 'react';
import PropTypes from 'prop-types';

const SidebarHeader = ({ children, toggleSidebar }) => {
  return (
    <header className="header">
      <button
        className="sidebar__menu-toggle menu-toggle"
        type="button"
        onClick={toggleSidebar}
      >
        <span className="toga-icon toga-icon-x-dismiss" aria-hidden />
      </button>
      {children}
    </header>
  );
};

SidebarHeader.defaultProps = {
  toggleSidebar: () => {},
};

SidebarHeader.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
  toggleSidebar: PropTypes.func,
};

SidebarHeader.displayName = 'SidebarHeader';

export default SidebarHeader;
