/* eslint-disable camelcase */
import React, { useReducer, useEffect, useRef, useState } from 'react';
import {
  Col,
  Row,
  Button,
  Caption,
  Label,
  LoadingButton,
  ImageInput,
  Input,
  CircularSpinner,
  Toggle,
  Modal,
  ModalBody,
  ModalFooter,
  Loader,
} from '@thinkific/toga-react';
import { useQuery, useMutation } from '@apollo/react-hooks';
import { useParams } from 'react-router-dom';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import Install from './Install';
import { Card } from '../../Card';
import ThemeExtensions from './ThemeExtensions';
import AvailableScopes from '../AvailableScopes';
import {
  AppDetailQuery,
  AppDetailMutation,
  FilestackSecurityQuery,
  ResetAppClientSecretMutation,
} from './graphql';
import { useUiContext } from '../../UiContext';
import validations from './validations';
import {
  initialState,
  isDirtyInitialState,
  reducer,
  isDirtyReducer,
} from './reducer';
import FALLBACK_APP_ICON from '../constants';
import { validateFields, isValid } from '../../../utils/validations';
import { graphqlMutationErrors } from '../../../utils/errors';
import styles from './AppDetail.module.scss';
import { installHandlerURL } from './handlers';
import { e2eId } from '../../../utils/test.utils';
import { LabelToolTip } from '../../LabelTooltip';
import { useFlags } from '../../../utils/flags';
import { ValidatedInput, ValidatedTextarea } from '../../ValidatedInputs';
import AppTabs from '../AppTabs';

const setFocusOnError = () => {
  setTimeout(() => {
    const firstError = document.querySelector('[isinvalid="true"]');
    if (firstError) {
      firstError.focus();
    }
  }, 100);
};

const AppDetail = () => {
  const { changePageProps, showToast } = useUiContext();
  const { id } = useParams();
  const [isOpen, setIsModalOpen] = useState(false);
  const [isConfirmed, setIsConfirmed] = useState(false);
  const [confirmText, setConfirmText] = useState('');
  const [isDirty, setIsDirty] = useReducer(isDirtyReducer, isDirtyInitialState);
  const originalState = useRef();
  const [state, dispatch] = useReducer(reducer, initialState);
  const [validationErrors, setValidationErrors] = useState({});
  const [copied, setCopied] = useState(null);
  const { data: filestackData } = useQuery(FilestackSecurityQuery);
  const {
    data,
    loading,
    refetch: refetchDetails,
  } = useQuery(AppDetailQuery, {
    variables: {
      id,
    },
  });

  const flags = useFlags();
  const clientOptions = filestackData?.filestackPolicy
    ? { security: filestackData?.filestackPolicy }
    : {};

  const {
    slug,
    name,
    url,
    redirect_uris,
    scopes,
    description,
    support_email,
    support_contact_form_url,
    support_doc_link,
    logo,
    api_contact_email,
    author,
    website,
    subdomain,
    modified,
    extensions,
  } = state;

  const [resetAppClientSecret] = useMutation(ResetAppClientSecretMutation, {
    variables: { id },
  });

  const [updateApp, mutationData] = useMutation(AppDetailMutation, {
    variables: {
      slug,
      id,
      name,
      url,
      redirect_uris,
      scopes,
      description,
      support_email,
      support_contact_form_url,
      support_doc_link,
      logo,
      api_contact_email,
      author,
      website,
      extensions: {
        app_settings: extensions.app_settings,
        communities_background: extensions.communities_background,
        course_player_background: extensions.course_player_background,
        performance_checkout_background:
          extensions.performance_checkout_background,
        theme_background: extensions.theme_background,
      },
    },
  });

  const app = data?.app || null;
  const appLogo = logo || (app ? app.logo : FALLBACK_APP_ICON);
  const appData = mutationData.data ? mutationData.data.updateApp : app;
  const allowInstall = isValid(validateFields(appData, validations));
  const saving = mutationData.loading;
  const filestackOptions = {
    accept: ['.jpeg', '.png', '.svg', '.gif'],
    transformations: {
      crop: {
        aspectRatio: 1 / 1,
        force: true,
      },
    },
  };

  if (app && !modified) {
    dispatch({
      key: 'merge',
      value: app,
    });
    originalState.current = app;
    changePageProps({
      title: app.name,
      icon: appLogo,
    });
  }

  // eslint-disable-next-line consistent-return
  const onSubmit = (event) => {
    event.preventDefault();

    const lastErrors = validateFields(state, validations);
    if (!isValid(lastErrors)) {
      setValidationErrors(lastErrors);
      return setFocusOnError();
    }

    setValidationErrors({});

    if (isDirty.extendsAppSettings) {
      return setIsModalOpen(true);
    }

    return handleSave();
  };

  async function handleSave() {
    try {
      const response = await updateApp();
      if (response.data) {
        onUpdateSuccess('Settings and details saved successfully');
      }
    } catch (e) {
      showToast('alert', graphqlMutationErrors(e));
    }
  }

  const onUpdateSuccess = (msg) => {
    showToast('notice', msg);
    dispatch({
      key: 'merge',
      value: state,
    });
    reset();
  };

  function reset() {
    originalState.current = state;
    setIsDirty({ key: 'extendsAppSettings', value: false });
    setConfirmText('');
    setIsModalOpen(false);
  }

  function handleConfirmTextChange(e) {
    setConfirmText(e.target.value);
    if (e.target.value === 'confirm') {
      setIsConfirmed(true);
    } else {
      setIsConfirmed(false);
    }
  }

  useEffect(() => {
    return () => {
      changePageProps({});
    };
  }, [changePageProps]);

  useEffect(() => {
    if (originalState.current) {
      const {
        extensions: { app_settings: originalExtendsAppSettings },
      } = originalState.current;
      const {
        extensions: { app_settings: currentExtendsAppSettings },
      } = state;

      if (originalExtendsAppSettings !== currentExtendsAppSettings) {
        setIsDirty({ key: 'extendsAppSettings', value: true });
      } else {
        setIsDirty({ key: 'extendsAppSettings', value: false });
      }
    }
  }, [state.extensions.app_settings]);

  const onCopyCallback = async (field) => {
    setCopied(field);
    setTimeout(() => setCopied(null), 2000);
  };

  const resetClientKey = async () => {
    try {
      const response = await resetAppClientSecret();
      if (response.data) {
        onUpdateSuccess('Client Secret reset successfully');
      }
    } catch (e) {
      showToast('alert', graphqlMutationErrors(e));
    }
  };

  const APIAccess = () => (
    <Card title="API access">
      <div className={styles.container}>
        <Col className={styles.input__wrapper}>
          <Input
            extraProps={{ readOnly: true, ...e2eId('clientKey') }}
            id="clientkey"
            initValue={app.client_id}
            label="Client key"
            name="clientKey"
          />
        </Col>
        <Col className={styles.access__btn}>
          <CopyToClipboard
            text={app.client_id}
            onCopy={() => onCopyCallback('client_id')}
          >
            <Button
              appearance="secondary"
              isActive={copied === 'client_id'}
              type="button"
            >
              {copied === 'client_id' ? 'COPIED!' : 'COPY'}
            </Button>
          </CopyToClipboard>
        </Col>
      </div>
      <div className={styles.container}>
        <Col className={styles.input__wrapper}>
          <Input
            extraProps={{ readOnly: true, ...e2eId('clientSecret') }}
            id="clientSecret"
            initValue={app.client_secret}
            label="Client secret"
            name="clientSecret"
          />
        </Col>
        <Col className={styles.access__btn}>
          <Button appearance="secondary" type="button" onClick={resetClientKey}>
            RESET
          </Button>
        </Col>
        <Col className={styles.access__btn}>
          <CopyToClipboard
            text={app.client_secret}
            onCopy={() => onCopyCallback('client_secret')}
          >
            <Button
              appearance="secondary"
              isActive={copied === 'client_secret'}
              type="button"
            >
              {copied === 'client_secret' ? 'COPIED!' : 'COPY'}
            </Button>
          </CopyToClipboard>
        </Col>
      </div>
    </Card>
  );

  const BasicSettingsSection = () => {
    return (
      <>
        <form onSubmit={(e) => onSubmit(e)}>
          <Row>
            {loading && <CircularSpinner variation="xlarge" />}
            {app && (
              <Col mdColumns={12}>
                <APIAccess />
                <Card title="Settings and details" collapsible>
                  <div className={styles.input__wrapper}>
                    <ValidatedInput
                      extraProps={{ disabled: saving }}
                      fieldErrors={validationErrors.name}
                      handleChange={(e) =>
                        dispatch({ key: 'name', value: e.target.value })
                      }
                      id="appName"
                      initValue={name}
                      label="App name *"
                      maxLength={30}
                      name="appName"
                      placeholder="My Awesome App"
                    />
                  </div>
                  {flags.appFrames && (
                    <div className={styles.input__wrapper}>
                      <ValidatedInput
                        extraProps={{ disabled: saving }}
                        fieldErrors={validationErrors.slug}
                        handleChange={(e) =>
                          dispatch({ key: 'slug', value: e.target.value })
                        }
                        id="appSlug"
                        initValue={slug}
                        label="App slug *"
                        maxLength={30}
                        name="appSlug"
                        placeholder="awesome-app"
                      />
                    </div>
                  )}
                  <div className={styles.input__wrapper}>
                    <ValidatedInput
                      extraProps={{ disabled: saving }}
                      fieldErrors={validationErrors.url}
                      formText={
                        validationErrors.url ? '' : 'Where your app is hosted'
                      }
                      handleChange={(e) =>
                        dispatch({ key: 'url', value: e.target.value })
                      }
                      id="appUrl"
                      initValue={url || ''}
                      label="App URL *"
                      name="appUrl"
                      placeholder="https://www.myawesomeapp.io/app"
                    />
                  </div>
                  <div className={styles.input__wrapper}>
                    <ValidatedTextarea
                      ariaLabel="Callback URLs *"
                      disabled={saving}
                      fieldErrors={validationErrors.redirect_uris}
                      formText="If you’re fancy and your app requires more than one callback URL—please list them on separate lines!"
                      id="callbackUrls"
                      label={
                        <LabelToolTip
                          containerClass={null}
                          label="Callback URLs *"
                          tipText="The users of your app are redirected to these whitelisted URLs after the app is installed."
                        />
                      }
                      name="callbackUrls"
                      rows={3}
                      value={redirect_uris.join('\n')}
                      onChange={(e) =>
                        dispatch({
                          key: 'redirect_uris',
                          value: e.target.value,
                        })
                      }
                    />
                  </div>
                  <div className={styles.input__wrapper}>
                    <ValidatedTextarea
                      ariaLabel="Description *"
                      disabled={saving}
                      fieldErrors={validationErrors.description}
                      id="description"
                      label={
                        <LabelToolTip
                          containerClass={null}
                          label="Description *"
                          tipText="This is your chance to tell users what they've been missing out on—until now that is."
                        />
                      }
                      name="description"
                      rows={3}
                      value={description || ''}
                      onChange={(e) =>
                        dispatch({ key: 'description', value: e.target.value })
                      }
                    />
                  </div>
                  <div className={styles.input__wrapper}>
                    <ValidatedInput
                      extraProps={{ disabled: saving }}
                      fieldErrors={validationErrors.support_email}
                      handleChange={(e) =>
                        dispatch({
                          key: 'support_email',
                          value: e.target.value,
                        })
                      }
                      id="customerSupportEmail"
                      initValue={support_email || ''}
                      label="Customer support email *"
                      name="customerSupportEmail"
                    />
                  </div>
                  <div className={styles.input__wrapper}>
                    <LabelToolTip
                      label="Customer support contact form URL"
                      tipText="If you prefer to use a contact form, we’ll default to this link wherever your customer support contact is shown."
                    />
                    <ValidatedInput
                      extraProps={{ disabled: saving }}
                      fieldErrors={validationErrors.support_contact_form_url}
                      handleChange={(e) =>
                        dispatch({
                          key: 'support_contact_form_url',
                          value: e.target.value,
                        })
                      }
                      id="customerSupportContactForm"
                      initValue={support_contact_form_url || ''}
                      name="customerSupportContactForm"
                    />
                  </div>
                  <div className={styles.input__wrapper}>
                    <ValidatedInput
                      extraProps={{ disabled: saving }}
                      fieldErrors={validationErrors.support_doc_link}
                      handleChange={(e) =>
                        dispatch({
                          key: 'support_doc_link',
                          value: e.target.value,
                        })
                      }
                      id="supportDocForm"
                      initValue={support_doc_link || ''}
                      label="Support documentation link *"
                      name="supportDocForm"
                    />
                  </div>
                  <div className={styles.input__wrapper}>
                    <Label htmlFor="appIcon">App icon URL</Label>
                    {!!clientOptions.security ? (
                      <ImageInput
                        awsBucket={process.env.REACT_APP_FILESTACK_UPLOAD_BUCKET}
                        awsPath={
                          process.env.REACT_APP_FILESTACK_UPLOAD_BUCKET_PATH
                        }
                        awsRegion={
                          process.env.REACT_APP_FILESTACK_UPLOAD_BUCKET_REGION
                        }
                        clientOptions={clientOptions}
                        filestackAPIKey={process.env.REACT_APP_FILESTACK_API_KEY}
                        filestackOptions={filestackOptions}
                        id="appIcon"
                        initialImageUrl={appLogo}
                        isDisabled={saving}
                        name="appIcon"
                        placeholderImageUrl={FALLBACK_APP_ICON}
                        wrapperClass={styles.imageInput__wrapper}
                        onChange={(file) =>
                          dispatch({
                            key: 'logo',
                            value: `https://s3.amazonaws.com/${
                              file.container
                            }/${escape(file.key)}`,
                          })
                        }
                        onDelete={() =>
                          dispatch({ key: 'logo', value: FALLBACK_APP_ICON })
                        }
                        onError={(error) => showToast('alert', error)}
                      />
                    ) : (
                      <Loader />
                    )}
                    <Caption>
                      Suggested dimensions: 218px by 218px. JPG, PNG, SVG or GIF
                      format.
                    </Caption>
                  </div>
                  <div className={styles.input__wrapper}>
                    <ValidatedInput
                      extraProps={{ disabled: saving }}
                      fieldErrors={validationErrors.author}
                      handleChange={(e) =>
                        dispatch({ key: 'author', value: e.target.value })
                      }
                      id="appAuthor"
                      initValue={author || ''}
                      label="Author or company name *"
                      maxLength={30}
                      name="appAuthor"
                    />
                  </div>
                  <div className={styles.input__wrapper}>
                    <LabelToolTip
                      label="API contact email"
                      tipText="An email address that we can send all of the important stuff—like technical info and status updates."
                    />
                    <Input
                      extraProps={{ disabled: saving }}
                      handleChange={(e) =>
                        dispatch({
                          key: 'api_contact_email',
                          value: e.target.value,
                        })
                      }
                      id="apiContactEmail"
                      initValue={api_contact_email || ''}
                      name="apiContactEmail"
                    />
                  </div>
                  <div className={styles.input__wrapper}>
                    <ValidatedInput
                      extraProps={{ disabled: saving }}
                      fieldErrors={validationErrors.website}
                      handleChange={(e) =>
                        dispatch({ key: 'website', value: e.target.value })
                      }
                      id="appWebsite"
                      initValue={website || ''}
                      label="Marketing website"
                      name="appWebsite"
                    />
                  </div>
                  {flags.appFrames && (
                    <div className={styles.input__wrapper}>
                      <Label>Embed app within the Thinkific product</Label>
                      <Toggle
                        checked={extensions.app_settings}
                        id="extensions"
                        offLabel="Embed app"
                        subLabel={`Enabling this will embed your app under /manage/apps/${slug}`}
                        onChange={() => {
                          dispatch({
                            key: 'extensions',
                            value: {
                              // Only allowing app_settings to be configured until we have
                              // design to individually configure different frame locations
                              app_settings: !extensions.app_settings,
                              communities_background:
                                app.extensions.communities_background,
                              course_player_background:
                                app.extensions.course_player_background,
                              performance_checkout_background:
                                app.extensions.performance_checkout_background,
                              theme_background: app.extensions.theme_background,
                            },
                          });
                        }}
                        onLabel="Embed app"
                      />
                    </div>
                  )}

                  <footer className={styles.footer}>
                    <LoadingButton
                      appearance="primary"
                      data-cy="saveAppButton"
                      disabled={false}
                      hasSuccessDisabled={false}
                      iconColor="#36404D"
                      isLoading={saving}
                      loadingChildren="Saving..."
                      spinnerColor="#ffffff"
                      successChildren="Success!"
                      successTimeout={3000}
                      type="submit"
                    >
                      &nbsp;Save app details &nbsp;
                    </LoadingButton>
                  </footer>
                </Card>
                {flags.themeExtensions && (
                  <ThemeExtensions
                    appData={app}
                    clientOptions={clientOptions}
                    id={id}
                    onUpload={refetchDetails}
                  />
                )}

                <Install
                  allowInstall={allowInstall}
                  value={subdomain}
                  onChange={(value) =>
                    dispatch({
                      key: 'subdomain',
                      value,
                    })
                  }
                  onInstall={() => {
                    window.open(
                      installHandlerURL(subdomain, app.client_id, app.id),
                      '_self'
                    );
                  }}
                />

                {/* Disabled for now until this flow UX is discussed.
              Can be done via API - DELETE /app/<app_id>?partner_id=<partner_id>
              <Button>Delete App</Button>  */}
              </Col>
            )}
          </Row>
        </form>
        <Modal
          closeModal={() => setIsModalOpen(false)}
          isOpen={isOpen}
          style={{ content: { width: 480 } }}
          title="Confirm changes to your app's embed setting"
        >
          <ModalBody>
            <p>
              Changing the app embed setting will immediately change where your
              app is accessed by customers.
            </p>
            <br />
            <p>
              Confirm changes to your Settings &amp; details by filling in the
              field.
            </p>
            <br />

            <Input
              handleChange={handleConfirmTextChange}
              id="confirm"
              initValue={confirmText}
              label={`Type "confirm"`}
              name="confirm"
              type="text"
            />
          </ModalBody>
          <ModalFooter>
            <Button
              appearance="secondary"
              onClick={() => setIsModalOpen(false)}
            >
              Don&apos;t save changes
            </Button>
            <Button
              appearance="primary"
              isDisabled={!isConfirmed}
              hasDanger
              onClick={handleSave}
            >
              Continue and save
            </Button>
          </ModalFooter>
        </Modal>
      </>
    );
  };

  const ScopesSection = () => {
    return (
      <>
        {loading && <CircularSpinner variation="xlarge" />}
        {appData && <AvailableScopes appData={appData} dispatch={dispatch} />}
      </>
    );
  };

  return (
    <AppTabs
      tabs={[
        {
          id: 'basic-settings',
          title: 'Basic settings',
          component: BasicSettingsSection,
        },
        {
          id: 'scopes',
          title: 'Scopes',
          component: ScopesSection,
        },
      ]}
    />
  );
};

export default AppDetail;
