export const validations = {
  email: {
    email: {
      value: true,
      message: 'Invalid email format',
    },
  },
  password: {
    required: {
      value: true,
      message: 'Password is required',
    },
  },
};
