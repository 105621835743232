import { PASSWORD_CAPTION } from '../../../utils/contants';

const validations = {
  password: {
    required: {
      value: true,
      message: 'Password is required',
    },
  },
  newPassword: {
    required: {
      value: true,
      message: 'Password is required',
    },
    length: {
      value: 8,
      message: PASSWORD_CAPTION,
    },
    strength: {
      value: /(?=.*\d)(?=.*[A-Z])(?=.*[a-z])/,
      message: PASSWORD_CAPTION,
    },
  },
  confirmNewPassword: {
    match: {
      value: 'newPassword',
      message: 'Passwords do not match',
    },
  },
};

export const cognitoErrors = {
  InvalidParameterException: {
    password: {
      invalid: 'Incorrect password',
    },
  },
  LimitExceededException: {
    password: {
      invalid: 'Too many attempts. Please try again later.',
    },
  },
};
export default validations;
