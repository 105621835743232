import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Col, Row, Icon } from '@thinkific/toga-react';
import styles from './Card.module.scss';
import CardFooter from './CardFooter';

const Card = ({
  title,
  beta,
  collapsible,
  headerAction,
  primary,
  icon,
  children,
  hasFooter,
  footerAction,
  footerActionLabel,
  footerDescription,
  footerIcon,
}) => {
  const [isCollapsed, setIsCollapsed] = useState(!collapsible);

  const handleClick = (e) => {
    e.stopPropagation();
    if (collapsible) setIsCollapsed(!isCollapsed);
  };

  return (
    <div
      className={`${styles.container}
      ${hasFooter && styles.hasFooter}
      ${primary && styles.primary}
      ${collapsible && styles.collapsible}
      ${isCollapsed && styles.isCollapsed}`}
    >
      <Row>
        {icon && (
          <Col smColumns={1} data-card-icon>
            <i
              className={`${styles.icon} toga-product-icon toga-product-icon-${icon}`}
            />
          </Col>
        )}
        <Col data-icon-content={!!icon} smColumns={icon ? 11 : 12}>
          {/* eslint-disable-next-line */}
          <div className={styles.header__container} onClick={handleClick}>
            <h3 className={styles.header__title}>
              {title}{' '}
              {beta && <Icon color="#5668C7" name="beta" title="Beta" />}
            </h3>
            {headerAction && (
              <div className={styles.header__actions}>{headerAction}</div>
            )}
            {collapsible && (
              <Icon
                name={`caret-stroke-${isCollapsed ? 'up' : 'down'}`}
                title="Collapse"
              />
            )}
          </div>
          <div className={styles.content}>{children}</div>
        </Col>
      </Row>

      {hasFooter && (
        <CardFooter
          action={footerAction}
          actionLabel={footerActionLabel}
          description={footerDescription}
          icon={footerIcon}
        />
      )}
    </div>
  );
};

Card.defaultProps = {
  beta: false,
  collapsible: false,
  headerAction: null,
  icon: null,
  primary: false,
  hasFooter: false,
  footerAction: () => {},
  footerActionLabel: '',
  footerDescription: '',
  footerIcon: '',
};

Card.propTypes = {
  beta: PropTypes.bool,
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
  collapsible: PropTypes.bool,
  footerAction: PropTypes.func,
  footerActionLabel: PropTypes.string,
  footerDescription: PropTypes.string,
  footerIcon: PropTypes.string,
  hasFooter: PropTypes.bool,
  headerAction: PropTypes.node,
  icon: PropTypes.string,
  primary: PropTypes.bool,
  title: PropTypes.string.isRequired,
};

Card.displayName = 'Card';

export default Card;
