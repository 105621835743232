import ROUTES from './routeConstants';

export const editAppPath = (id) =>
  id ? ROUTES.APP_EDIT.PATH.replace(':id', id) : ROUTES.APP_EDIT.PATH;
export const indexPath = () => ROUTES.INDEX.PATH;
export const newsPath = () => ROUTES.NEWS.PATH;
export const newsViewPath = (id) =>
  id ? ROUTES.NEWS_DETAILS.PATH.replace(':slug', id) : ROUTES.NEWS_DETAILS.PATH;
export const sitesPath = () => ROUTES.SITES.PATH;
export const sitesManagedPath = () => ROUTES.SITES_MANAGED.PATH;
export const sitesDevelopmentPath = () => ROUTES.SITES_DEVELOPMENT.PATH;
export const appsPath = () => ROUTES.APPS.PATH;
export const createAppPath = () => ROUTES.APP_CREATE.PATH;
export const accountPath = () => ROUTES.ACCOUNT.PATH;
export const oauthCallbackPath = () => ROUTES.CALLBACK.PATH;
export const roadmapPath = () => ROUTES.ROADMAP.PATH;
