import React from 'react';
import { useQuery } from '@apollo/react-hooks';
import { useParams } from 'react-router-dom';
import TimeAgo from 'react-timeago';
import ReactMarkdown from 'react-markdown';
import CircularSpinner from '@thinkific/toga-circular-spinner';
import styles from './NewsDetail.module.scss';
// import { TYPEFORM_ID } from './constants';
import NewsQuery from '../NewsQuery';
import { Card } from '../../Card';
// import useFeedbackModal from '../../../hooks/useFeedbackModal';

const NewsDetail = () => {
  const { slug } = useParams();
  const { data, loading } = useQuery(NewsQuery, {
    variables: {
      limit: 1,
      slug,
    },
  });
  // const openFeedbackModal = useFeedbackModal(TYPEFORM_ID);
  const post = data && data.blogPosts && data.blogPosts.items[0];

  return (
    <>
      {loading && <CircularSpinner />}
      {post && (
        <Card title={post.title}>
          <p className={styles.publishedAt}>
            <TimeAgo date={post.publishedAt} />
          </p>
          <img alt={post.slug} className={styles.image} src={post.heroImage} />
          <ReactMarkdown className={styles.article} source={post.body} />
        </Card>
      )}
    </>
  );
};

NewsDetail.displayName = 'NewsDetail';

export default NewsDetail;
