import gql from 'graphql-tag';

const TenantsQuery = gql`
  query Tenants {
    tenants(limit: 1) {
      items {
        id
        subdomain
      }
    }
  }
`;

export default TenantsQuery;
