import React from 'react';
import PropTypes from 'prop-types';
import Button from '@thinkific/toga-button';
import { TYPES } from '../constants';
import InlineAlert from '../../../InlineAlert';

const WarningAlert = ({ message, onResetState }) => {
  return (
    <>
      <InlineAlert message={message} type={TYPES.WARNING} />
      <div className="d-md-flex justify-content-end align-items-center">
        <Button onClick={onResetState}>Enter a different email</Button>
      </div>
    </>
  );
};

WarningAlert.propTypes = {
  message: PropTypes.string.isRequired,
  onResetState: PropTypes.func.isRequired,
};

WarningAlert.displayName = 'WarningAlert';

export default WarningAlert;
