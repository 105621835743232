import { shallow } from 'enzyme';

const getElementById = (wrapper, id) => wrapper.find(`#${id}`);

const getByText = (wrapper, value) =>
  wrapper.findWhere((element) => element.text() === value);

const fillForm = (wrapper, values) => {
  if (!Array.isArray(values)) {
    throw new Error('Need an array of objects');
  }
  values.forEach((item) => {
    const field = wrapper.find(`[name="${item.name}"]`).get(0);
    if (field) {
      const itemContainer = shallow(field);
      const input = itemContainer.find('input');
      // console.log(itemContainer.debug());
      if (input.get(0)) {
        const event = item.checkbox
          ? { target: { value: item.value }, persist: () => {} }
          : { target: { value: item.value } };
        input.simulate('focus');
        input.simulate('change', event);
      }
    }
  });
};

const e2eId = (label) => ({ 'data-cy': label });

export { getByText, getElementById, fillForm, e2eId };
