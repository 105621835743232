const KB_DASHBOARD =
  'https://support.thinkific.com/hc/en-us/categories/360001926174-Partners-Developers';

const SECTION_IDS = {
  ABOUT: '360004603593',
  DEVELOPER_RESOURCES: '360004564454',
  POLICIES: '360004564474',
  API_DOCUMENTATION: '360004603613',
};

const LIMIT = 15;

const TYPEFORM_ID = 'kwRsWQ';

export { LIMIT, KB_DASHBOARD, SECTION_IDS, TYPEFORM_ID };
