import gql from 'graphql-tag';

const SitesQuery = gql`
  query Sites($limit: Int, $nextToken: String) {
    sites(limit: $limit, nextToken: $nextToken) {
      items {
        id
        name
        subdomain
        status
      }
      nextToken
    }
  }
`;

export default SitesQuery;
