import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { TYPES } from './constants';

const InlineAlert = ({ message, type, isFirstElement }) => {
  let icon;

  switch (type) {
    case TYPES.SUCCESS:
      icon = 'circle-fill-check';
      break;
    case TYPES.INFO:
      icon = 'circle-fill-info';
      break;
    case TYPES.WARNING:
      icon = 'warning';
      break;
    case TYPES.DANGER:
      icon = 'stop-sign';
      break;
    default:
      break;
  }

  const inlineAlertClasses = classNames(
    'inline__alert ',
    `inline__alert--${type}`,
    {
      'inline__alert--first-element': isFirstElement,
    }
  );
  return (
    <div className={inlineAlertClasses}>
      <div className="inline__alert-text__wrapper">
        <i className={`toga-icon toga-icon-${icon}`} />
        <div className="inline__alert-text">{message}</div>
      </div>
    </div>
  );
};

InlineAlert.defaultProps = {
  isFirstElement: false,
};

InlineAlert.propTypes = {
  isFirstElement: PropTypes.bool,
  message: PropTypes.string.isRequired,
  type: PropTypes.string.isRequired,
};

InlineAlert.displayName = 'InlineAlert';

export default InlineAlert;
