import gql from 'graphql-tag';
import { LIMIT, SECTION_IDS } from './constants';

const ZenDeskSectionArticlesQuery = gql`
  query SectionArticles {
    aboutArticles: zenDeskSectionArticles(
      sectionId: "${SECTION_IDS.ABOUT}"
      limit: ${LIMIT}
    ) {
      items {
        id
        title
        htmlUrl
      }
    }
    developerResourceArticles: zenDeskSectionArticles(
      sectionId: "${SECTION_IDS.DEVELOPER_RESOURCES}"
      limit: ${LIMIT}
    ) {
      items {
        id
        title
        htmlUrl
      }
    }
    policyArticles: zenDeskSectionArticles(
      sectionId: "${SECTION_IDS.POLICIES}"
      limit: ${LIMIT}
    ) {
      items {
        id
        title
        htmlUrl
      }
    }
    apiDocumentationArticles: zenDeskSectionArticles(
      sectionId: "${SECTION_IDS.API_DOCUMENTATION}"
      limit: ${LIMIT}
    ) {
      items {
        id
        title
        htmlUrl
      }
    }
  }
`;

export default ZenDeskSectionArticlesQuery;
