const validations = {
  name: {
    required: {
      value: true,
      message: 'App name required',
    },
  },
  url: {
    required: {
      value: true,
      message: 'App URL is required',
    },
    urls: {
      value: true,
      message: 'App url must contain http:// or https://',
    },
  },
  redirect_uris: {
    required: {
      value: true,
      message: 'Callback urls required',
    },
    urls: {
      value: true,
      message: 'Callback urls must contain http:// or https://',
    },
  },
  description: {
    required: {
      value: true,
      message: 'Description is required',
    },
  },
  support_email: {
    required: {
      value: true,
      message: 'Customer support email required',
    },
    email: {
      value: true,
      message: 'Email format is invalid',
    },
  },
  website: {
    urls: {
      value: true,
      message: 'Marketing website must contain http:// or https://',
    },
  },
  support_contact_form_url: {
    urls: {
      value: true,
      message: 'Support contact form url must contain http:// or https://',
    },
  },
  support_doc_link: {
    required: {
      value: true,
      message: 'Support documentation link required',
    },
    urls: {
      value: true,
      message: 'Support documentation link must contain http:// or https://',
    },
  },
  author: {
    required: {
      value: true,
      message: 'Author or company name required',
    },
  },
};
export default validations;
