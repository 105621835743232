import React, { useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import axios from 'axios';
import { editAppPath } from '../../utils/routes';
import { useUiContext } from '../UiContext';

const Callback = () => {
  const history = useHistory();

  const goToApp = (id) => {
    history.push(editAppPath(id));
  };

  const { showToast } = useUiContext();

  const requestToken = async () => {
    const url = new URLSearchParams(window.location.search);
    const subdomain = url.get('subdomain');
    const { codeVerifier, clientId, appId } = JSON.parse(
      localStorage.getItem(`${subdomain}.pkceObj`)
    );

    const tokenUrl = `${window.location.protocol}//${subdomain}.${process.env.REACT_APP_THINKIFIC_DOMAIN}/oauth2/token`;
    const tokenResponse = await axios.post(
      tokenUrl,
      {
        grant_type: 'authorization_code',
        code_verifier: codeVerifier,
        code: url.get('code'),
      },
      {
        auth: {
          username: clientId,
        },
      }
    );

    if (tokenResponse.status === 200) {
      localStorage.removeItem(`${subdomain}.pkceObj`);
      showToast('notice', 'Successfully installed the App');
      goToApp(appId);
    } else {
      showToast(
        'alert',
        'Something went wrong when installing the App. Please check your redirect URLs'
      );
    }
  };

  useEffect(() => {
    requestToken();
  }, []);

  return <></>;
};

Callback.displayName = 'Callback';

export default Callback;
