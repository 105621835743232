import React from 'react';
import shortid from 'shortid';
import PropTypes from 'prop-types';
import EmptyState from '@thinkific/toga-empty-state';
import Surface from '@thinkific/toga-surface';
import Button, { withIcon } from '@thinkific/toga-button';
import CircularSpinner from '@thinkific/toga-circular-spinner';
import SiteDataTable from './SiteDataTable';
import { useUiContext } from '../../UiContext';
import { CONNECT_CLIENT_SITE_MODAL } from '../../Modal';
import Icons from '../../Icons';
import { e2eId } from '../../../utils/test.utils';
import withQuery from '../../../hocs/withQuery';
import SitesQuery from '../../../graphql/Query/Sites';
import styles from './ManagedSites.module.scss';

const ButtonWithIconLeft = withIcon({ position: 'left' })(Button);

function ManagedSites({ query }) {
  const { data: queryData, loading: queryLoading, error: queryError } = query;
  const { launchModal } = useUiContext();

  function openModal() {
    launchModal({
      type: CONNECT_CLIENT_SITE_MODAL,
    });
  }
  async function refetch() {
    // eslint-disable-next-line react/prop-types
    await query.refetch();
  }

  if (queryLoading) {
    return (
      <div className={styles.spinner}>
        <CircularSpinner variation="xlarge" />
      </div>
    );
  }

  if (queryError) {
    return <div>Something went wrong</div>;
  }

  if (queryData && queryData.sites.items.length === 0) {
    return (
      <Surface>
        <EmptyState
          actions={[
            <ButtonWithIconLeft
              appearance="primary"
              key={shortid.generate()}
              onClick={openModal}
              {...e2eId('openConnectClientSiteModalFromEmptyState')}
            >
              <Icons.Plus name="plus" />
              Connect Site
            </ButtonWithIconLeft>,
          ]}
          description="Forget the days of searching for credentials! By connecting to your managed sites, you’ll be able to view and log in directly from here."
          icon="product-students"
          referenceLink="https://support.thinkific.com/hc/en-us/articles/360042916854"
          referenceLinkLabel="Learn more"
          title="Connect your first managed site."
        />
      </Surface>
    );
  }

  return <SiteDataTable data={queryData.sites} reloadTable={refetch} />;
}

ManagedSites.displayName = 'ManagedSites';

ManagedSites.propTypes = {
  query: PropTypes.shape({
    data: PropTypes.object,
    error: PropTypes.bool,
    loading: PropTypes.bool,
  }),
};

ManagedSites.defaultProps = {
  query: {
    data: {},
    loading: false,
    error: false,
  },
};

export { ManagedSites };
export default withQuery(ManagedSites, SitesQuery, {
  variables: { limit: 250 },
});
