import gql from 'graphql-tag';

const ConnectClientSiteMutation = gql`
  mutation ConnectClientSite($subdomain: String!) {
    connectClientSite(subdomain: $subdomain) {
      site {
        id
        partnerId
        subdomain
        name
        status
        createdAt
        updatedAt
      }
    }
  }
`;

export default ConnectClientSiteMutation;
