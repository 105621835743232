import React, { useState, useReducer } from 'react';
import Input from '@thinkific/toga-input';
import { NavLink } from 'react-router-dom';
import Button from '@thinkific/toga-button';
import { useUserContext } from '../../UserProvider';
import { validations } from './validations';
import {
  validateFields,
  DEFAULT_ERRORS_CLASSNAME,
} from '../../../utils/validations';
import styles from './Signin.module.scss';

const initialState = {
  email: '',
  password: '',
};

const reducer = (state, action) => {
  const { key, value } = action;
  return {
    ...state,
    [key]: value,
  };
};

const serverrErrorsMap = {
  UserNotFoundException: {
    email: {
      notfound: 'User does not exist.',
    },
  },
  NotAuthorizedException: {
    password: {
      invalid: 'Incorrect username or password.',
    },
  },
  PasswordResetRequiredException: {
    email: {
      notfound:
        'User requires a password reset. Use the Forgot Password link below.',
    },
  },
};

const defaultError = {
  email: {
    email: 'Sorry, there was an error processing your request.',
  },
};

const SignIn = () => {
  const [state, dispatch] = useReducer(reducer, initialState);
  const { onSignIn } = useUserContext();
  const [validationErrors, setValidationErrors] = useState({});
  const { email, password } = state;

  // eslint-disable-next-line consistent-return
  const handleOnSubmit = async (event) => {
    event.preventDefault();

    const lastErrors = validateFields(state, validations);
    setValidationErrors(lastErrors);

    if (Object.keys(lastErrors).length > 0) {
      return false;
    }

    try {
      setValidationErrors({});
      await onSignIn(state);
    } catch (err) {
      const { code } = err;
      const tempError = serverrErrorsMap[code] || defaultError;
      setValidationErrors(tempError);
    }
  };

  const isValid = () => {
    return password.length >= 8 && email.length;
  };

  return (
    <form id="signinForm" noValidate onSubmit={handleOnSubmit}>
      <div className="Welcome__Header">
        <h1>Welcome back</h1>
      </div>
      <div className="Welcome__Surface mb-5">
        <div className="form-row">
          <div className="col-md-12">
            <Input
              handleChange={(e) =>
                dispatch({ key: 'email', value: e.target.value })
              }
              id="email"
              initValue={email}
              label="Email"
              name="email"
              placeholder="you@example.com"
              type="email"
            />
            {validationErrors.email && validationErrors.email.email && (
              <span className={DEFAULT_ERRORS_CLASSNAME}>
                {validationErrors.email.email}
              </span>
            )}
            {validationErrors.email && validationErrors.email.notfound && (
              <span className={DEFAULT_ERRORS_CLASSNAME}>
                {validationErrors.email.notfound}
              </span>
            )}
          </div>
        </div>
        <div className="form-row">
          <div className="col-md-12 mb-0">
            <Input
              handleChange={(e) =>
                dispatch({ key: 'password', value: e.target.value })
              }
              id="password"
              initValue={password}
              label="Password"
              name="password"
              type="password"
              onEnter={(e) => {
                return isValid() && handleOnSubmit(e);
              }}
            />
            {validationErrors.password &&
              validationErrors.password.required && (
                <span className={DEFAULT_ERRORS_CLASSNAME}>
                  {validationErrors.password.required}
                </span>
              )}
            {validationErrors.password && validationErrors.password.invalid && (
              <span className={DEFAULT_ERRORS_CLASSNAME}>
                {validationErrors.password.invalid}
              </span>
            )}
            <p className="mt-4">
              <NavLink aria-expanded="false" to="/welcome/help" exact>
                Forgot password?
              </NavLink>
            </p>
          </div>
        </div>
        <footer className={styles.footer}>
          <p>
            Don&apos;t have a partner account yet?&nbsp;
            <a href="https://www.thinkific.com/partners">Sign up</a>
          </p>
          <Button id="signin" type="submit">
            CONTINUE
          </Button>
        </footer>
      </div>
    </form>
  );
};

SignIn.displayName = 'SignIn';

export default SignIn;
