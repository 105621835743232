import gql from 'graphql-tag';

export const createAppMutationQuery = gql`
  mutation CreateApp(
    $appName: String!
    $appUrl: String!
    $redirectUris: [String!]
  ) {
    createApp(appName: $appName, appUrl: $appUrl, redirectUris: $redirectUris) {
      id
    }
  }
`;
