/* eslint-disable no-useless-escape */
import { EMAIL } from './messages';

const emailRegex =
  /^(([^<>()\[\]\\.,;:^&*#%~!$\s@"]+(\.[^<>()\[\]\\.,;:^&*#%~!$\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
const emailValidation = (fields, key, criteria) => {
  const field = fields[key];
  const message = criteria.message || EMAIL;
  return emailRegex.test(field) ? null : message;
};
export default emailValidation;
