import React from 'react';
import PropTypes from 'prop-types';
import { NavLink } from 'react-router-dom';
import Surface from '@thinkific/toga-surface';
import styles from './AppCard.module.scss';
import { editAppPath } from '../../../utils/routes';
import { e2eId } from '../../../utils/test.utils';
import AppIcon from '../AppIcon';

const AppCard = ({ icon, id, name }) => {
  return (
    <NavLink
      aria-expanded="false"
      className={styles.container}
      to={editAppPath(id)}
      exact
    >
      <Surface className={styles.surface}>
        <div className={styles.info__container}>
          <AppIcon alt={name} src={icon} />
          <i
            className={`toga-icon toga-icon-arrow-right ${styles['arrow-icon']}`}
          />
        </div>
        <h4 className={styles.name} {...e2eId(`appCardTitle${name}`)}>
          {name}
        </h4>
      </Surface>
    </NavLink>
  );
};

AppCard.defaultProps = {
  icon: '',
  id: '',
  name: '',
};

AppCard.propTypes = {
  icon: PropTypes.string,
  id: PropTypes.string,
  name: PropTypes.string,
};

export default AppCard;
