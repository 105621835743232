import React from 'react';
import { useMutation } from '@apollo/react-hooks';

const withMutation = (Component, gql, opts = {}) => {
  const WithMutationComponent = (props) => {
    const [action, data] = useMutation(gql, opts);
    const mutation = { action, data };
    return <Component mutation={mutation} {...props} />;
  };
  const mutationName = gql.definitions[0].name.value;
  WithMutationComponent.displayName = `WithMutation.${mutationName}`;
  return WithMutationComponent;
};

export default withMutation;
