import React from 'react';
import PropTypes from 'prop-types';
import Textarea from '@thinkific/toga-textarea';
import Input from '@thinkific/toga-input';
import classNames from 'classnames';
import styles from './CreateAppForm.module.scss';
import { DEFAULT_ERRORS_CLASSNAME } from '../../../utils/validations';
import { e2eId } from '../../../utils/test.utils';
import { LabelToolTip } from '../../LabelTooltip';

const validationErrorStyles = classNames(
  DEFAULT_ERRORS_CLASSNAME,
  styles.validationErrorField
);

const CreateAppForm = ({ formStore, validationErrors, isDisabled }) => {
  return (
    <>
      <p>These are the fields we need to get started.</p>
      <br />
      <fieldset aria-busy={isDisabled} disabled={isDisabled}>
        <div className="form-group">
          <Input
            extraProps={e2eId('appName')}
            handleChange={(event) =>
              formStore.setField('appName', event.target.value)
            }
            id="appName"
            initValue={formStore.getField('appName')}
            label="App name *"
            maxLength={30}
            name="appName"
            placeholder="My Awesome App"
            value={formStore.getField('appName')}
          />
          {validationErrors.appName && validationErrors.appName.required && (
            <span className={validationErrorStyles}>
              {validationErrors.appName.required}
            </span>
          )}
        </div>
        <div className="form-group">
          <Input
            extraProps={e2eId('appUrl')}
            formText={validationErrors.appUrl ? '' : 'Where your app is hosted'}
            handleChange={(event) =>
              formStore.setField('appUrl', event.target.value)
            }
            id="appUrl"
            initValue={formStore.getField('appUrl')}
            label="App URL *"
            name="appUrl"
            placeholder="https://www.myawesomeapp.io/app"
            value={formStore.getField('appUrl')}
          />
          {validationErrors.appUrl && validationErrors.appUrl.required && (
            <div className={validationErrorStyles}>
              {validationErrors.appUrl.required}
            </div>
          )}
          {validationErrors.appUrl && validationErrors.appUrl.urls && (
            <div className={validationErrorStyles}>
              {validationErrors.appUrl.urls}
            </div>
          )}
        </div>
        <div className="form-group">
          <LabelToolTip
            label="Callback URLs *"
            tipText="The users of your app are redirected to these whitelisted URLs after the app is installed."
          />
          <Textarea
            formText={
              validationErrors.redirectUris
                ? ''
                : 'If you’re fancy and your app requires more than one callback URL—please list them on separate lines!'
            }
            id="redirectUris"
            name="redirectUris"
            placeholder={'URL 1\nURL 2\nURL 3'}
            rows="3"
            onChange={(event) =>
              formStore.setField('redirectUris', event.target.value)
            }
            {...e2eId('redirectUris')}
          />
          {validationErrors.redirectUris &&
            validationErrors.redirectUris.required && (
              <div className={validationErrorStyles}>
                {validationErrors.redirectUris.required}
              </div>
            )}
          {validationErrors.redirectUris &&
            validationErrors.redirectUris.urls && (
              <div className={validationErrorStyles}>
                {validationErrors.redirectUris.urls}
              </div>
            )}
        </div>
      </fieldset>
    </>
  );
};

CreateAppForm.propTypes = {
  formStore: PropTypes.shape({
    getField: PropTypes.func,
    getState: PropTypes.func,
    setField: PropTypes.func,
  }),
  isDisabled: PropTypes.bool,
  validationErrors: PropTypes.shape({
    appName: PropTypes.object,
    appUrl: PropTypes.object,
    redirectUris: PropTypes.object,
  }),
};

CreateAppForm.defaultProps = {
  validationErrors: {},
  formStore: {
    setField: () => {},
    getField: () => {},
    getState: () => {},
  },
  isDisabled: false,
};

export { CreateAppForm };
