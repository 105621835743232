const checkSiteDomain = (tenant, tenants) => {
  if (tenants && tenants.items.length) {
    return {
      hasDemoSite: true,
      subdomain: tenants.items[0].subdomain,
      tenantId: tenants.items[0].id,
    };
  }

  if (tenant) {
    return {
      hasDemoSite: true,
      subdomain: tenant.subdomain,
      tenantId: tenant.id,
    };
  }
  return { hasDemoSite: false, subdomain: null, tenantId: '' };
};

export default checkSiteDomain;
