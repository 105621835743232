import React from 'react';
import Button from '@thinkific/toga-button';
import Input from '@thinkific/toga-input';
import CircularSpinner from '@thinkific/toga-circular-spinner';
import PropTypes from 'prop-types';
import { Card } from '../../../Card';
import DemoSiteCardFooter from '../DemoSiteCardFooter';

const DemoSiteCard = ({
  loading,
  hasDemoSite,
  createDemoSite,
  tenantId,
  siteUrl,
}) => {
  return (
    <Card title="Sandbox Site">
      <p>
        Your sandbox site lets you test out and demonstrate all of Thinkific’s
        latest features. Sales are not enabled for sandbox sites.
      </p>
      <br />

      {!loading && !hasDemoSite && (
        <Button id="createDemoSite" onClick={createDemoSite}>
          CREATE DEMO SITE
        </Button>
      )}

      {loading && (
        <div className="form-row">
          <CircularSpinner />
        </div>
      )}

      {!loading && hasDemoSite && (
        <div className="form-row">
          <div className="form-group col-sm-12 mb-12">
            <Input
              extraProps={{ disabled: true }}
              initValue={siteUrl}
              label="Demo Site URL"
              name="siteUrl"
              type="text"
            />
            <small className="form-text text-muted">
              You can change this in the settings area of your demo site admin.
            </small>
          </div>
        </div>
      )}

      {hasDemoSite && (
        <DemoSiteCardFooter siteUrl={siteUrl} tenantId={tenantId} />
      )}
    </Card>
  );
};

DemoSiteCard.propTypes = {
  createDemoSite: PropTypes.func.isRequired,
  hasDemoSite: PropTypes.bool.isRequired,
  loading: PropTypes.bool.isRequired,
  siteUrl: PropTypes.string.isRequired,
  tenantId: PropTypes.string.isRequired,
};

DemoSiteCard.displayName = 'DemoSiteCard';

export default DemoSiteCard;
