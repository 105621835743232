import React from 'react';
import PropTypes from 'prop-types';
import { NavLink } from 'react-router-dom';
import styles from './Breadcrumb.module.scss';
import { e2eId } from '../../utils/test.utils';

const Breadcrumb = ({ hasBackground, label, path, singleColumn }) => {
  return (
    <NavLink
      aria-expanded="false"
      className={`
        ${styles.breadcrumb}
        ${singleColumn ? styles['breadcrumb--single-column'] : ''}
        ${hasBackground ? '' : styles['breadcrumb__no-bg']}
      `}
      to={path}
      exact
      {...e2eId('breadcrumbLink')}
    >
      <i className="toga-icon toga-icon-arrow-left" />
      {label}
    </NavLink>
  );
};

Breadcrumb.defaultProps = {
  hasBackground: true,
  singleColumn: false,
};

Breadcrumb.propTypes = {
  hasBackground: PropTypes.bool,
  label: PropTypes.string.isRequired,
  path: PropTypes.string.isRequired,
  singleColumn: PropTypes.bool,
};

Breadcrumb.displayName = 'Breadcrumb';

export default Breadcrumb;
