import React, { useEffect } from 'react';
import './zendeskScript';

const ZendeskWidget = () => {
  useEffect(() => {
    const script1 = document.createElement('script');
    script1.src =
      'https://static.zdassets.com/ekr/snippet.js?key=a844a3bc-c255-4f47-84c0-1e87d36070bf';
    script1.async = true;
    script1.id = 'ze-snippet';
    document.body.appendChild(script1);
    return () => {
      document.body.removeChild(script1);
    };
  }, []);

  return <></>;
};

ZendeskWidget.displayName = 'ZendeskWidget';

export default ZendeskWidget;
