import React, { useEffect } from 'react';
import { Col, Row } from '@thinkific/toga-grid';
import { useQuery } from '@apollo/react-hooks';
import CircularSpinner from '@thinkific/toga-circular-spinner';
import { useHistory, Switch, Route } from 'react-router-dom';
import AppsQuery from './AppsQuery';
import AppCard from './AppCard';
import EmptyAppCard from './EmptyAppCard';
import { CreateAppModal, validations } from './CreateAppModal';
import FALLBACK_APP_ICON from './constants';
import { e2eId } from '../../utils/test.utils';
import AppDetail from './AppDetail';
import { createAppPath, appsPath, editAppPath } from '../../utils/routes';

const Apps = () => {
  const { data, loading, refetch, fetchMore } = useQuery(AppsQuery, {
    variables: { limit: 20 },
  });

  // Fetch all apps up-front using paginated requests.
  useEffect(() => {
    const nextToken = data?.apps?.nextToken;
    if (nextToken) {
      fetchMore({
        variables: { nextToken },
        updateQuery: (prev, { fetchMoreResult }) => {
          if (!fetchMoreResult) return prev;
          return {
            apps: {
              ...fetchMoreResult.apps,
              items: [...prev.apps.items, ...fetchMoreResult.apps.items],
            },
          };
        },
      });
    }
  }, [data, fetchMore]);

  const apps = data && data.apps && data.apps.items;
  const history = useHistory();
  const {
    location: { pathname },
  } = history;
  const showThumbs = pathname.indexOf('edit') === -1;

  const goToApp = (id) => {
    history.push(editAppPath(id));
  };

  const toggleCreateRoute = () => {
    history.push(createAppPath());
  };

  if (loading) return <CircularSpinner variation="xlarge" />;

  return (
    <>
      {showThumbs && (
        <Row>
          {apps &&
            apps.map((app) => (
              <Col key={app.id} mdColumns={3} smColumns={4}>
                <AppCard
                  icon={app.logo || FALLBACK_APP_ICON}
                  id={app.id}
                  name={app.name}
                />
              </Col>
            ))}
          <Col mdColumns={3} smColumns={4}>
            <EmptyAppCard
              label={apps ? 'Create app' : 'Create your first app'}
              onClick={() => toggleCreateRoute()}
              {...e2eId('empty-app-card-container')}
            />
          </Col>
        </Row>
      )}
      <Switch>
        <Route
          path={createAppPath()}
          render={(props) => {
            return (
              <CreateAppModal
                closeModal={() => history.replace(appsPath())}
                validations={validations}
                isOpen
                onCreate={refetch}
                onSave={goToApp}
                {...props}
              />
            );
          }}
          exact
        />
        <Route component={AppDetail} path={editAppPath()} exact />
      </Switch>
    </>
  );
};

export default Apps;
