import { REQUIRED } from './messages';

const requireValidation = (fields, key, criteria) => {
  const field = fields[key];
  const message = criteria.message || REQUIRED;

  if (typeof field === 'boolean') {
    return !field ? message : null;
  }
  if (Array.isArray(field)) {
    const values = field.filter((v) => v.length);
    return values.length ? null : message;
  }
  return !field || !field.length ? message : null;
};
export default requireValidation;
