import React from 'react';
import { useQuery } from '@apollo/react-hooks';

const withQuery = (Component, gql, opts = { fetchPolicy: 'network-only' }) => {
  const WithQueryComponet = (props) => {
    const query = useQuery(gql, opts);
    return <Component {...props} query={query} />;
  };
  const queryName = gql.definitions[0].name.value;
  WithQueryComponet.displayName = `WithQuery.${queryName}`;
  return WithQueryComponet;
};
export default withQuery;
