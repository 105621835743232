import React from 'react';
import PropTypes from 'prop-types';
import { Col, Row } from '@thinkific/toga-grid';
import Button from '@thinkific/toga-button';
import styles from './CardFooter.module.scss';

const CardFooter = ({ action, actionLabel, description, icon }) => {
  return (
    <div className={styles.container}>
      <Row>
        <Col smColumns={1}>
          <i
            className={`toga-product-icon toga-product-icon-${icon} ${styles.icon}`}
          />
        </Col>
        <Col smColumns={11}>
          <p className={styles.description}>{description}</p>
          <Button appearance="primary" onClick={() => action()}>
            {actionLabel}
          </Button>
        </Col>
      </Row>
    </div>
  );
};

CardFooter.defaultProps = {
  action: () => {},
  actionLabel: '',
  description: '',
  icon: '',
};

CardFooter.propTypes = {
  action: PropTypes.func,
  actionLabel: PropTypes.string,
  description: PropTypes.string,
  icon: PropTypes.string,
};

CardFooter.displayName = 'CardFooter';

export default CardFooter;
