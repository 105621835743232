export const initialState = {
  name: '',
  redirect_uris: [],
  scopes: [],
  logo: '',
  description: '',
  url: '',
  support_email: '',
  support_contact_form_url: '',
  support_doc_link: '',
  author: '',
  api_contact_email: '',
  website: '',
  subdomain: '',
  modified: false,
  extensions: {
    app_settings: false,
    communities_background: false,
    course_player_background: false,
    performance_checkout_background: false,
    theme_background: false,
  },
};

export const isDirtyInitialState = {
  extendsAppSettings: false,
};

export const reducer = (state, action) => {
  const { key, value } = action;
  if (key === 'merge') {
    return {
      ...state,
      ...value,
      modified: true,
    };
  }
  if (key === 'redirect_uris') {
    return {
      ...state,
      [key]: value.split('\n'),
    };
  }
  return {
    ...state,
    [key]: value,
  };
};

export const isDirtyReducer = (state, action) => {
  const { key, value } = action;

  return {
    ...state,
    [key]: value,
  };
};
