/* eslint-disable no-console */
import React, { useEffect, useState } from 'react';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import {
  AuthenticationDetails,
  CognitoUserPool,
  CognitoUser,
  CognitoUserAttribute,
} from 'amazon-cognito-identity-js';
import { useLDClient } from 'launchdarkly-react-client-sdk';
import AWSCognitoConfig from '../../config/aws-cognito';
import { UserContext } from './context';
import delightedNpsSurvey from '../../utils/delightedNpsSurvey';

const poolData = {
  UserPoolId: AWSCognitoConfig.userPoolId,
  ClientId: AWSCognitoConfig.clientId,
};
const userPool = new CognitoUserPool(poolData);

const toCamel = (s) => {
  return s.replace(/([-_][a-z])/gi, ($1) => {
    return $1.toUpperCase().replace('-', '').replace('_', '');
  });
};

const toSnakeCase = (s) => {
  return s.replace(/[A-Z]/g, ($1) => {
    return $1.replace($1, `_${$1.toLowerCase()}`);
  });
};

/**
 * Fill the CongnitoUserAttributes
 * @param {Object} data
 */
const fillUserAttributes = (data) => {
  const excludedList = [
    'termsAndConditions',
    'passwordConfirmation',
    'password',
  ];
  return Object.keys(data)
    .filter((key) => !excludedList.includes(key))
    .map((key) => {
      const userAttribute =
        key === 'questionnaire'
          ? {
              Name: 'custom:questionnaire',
              Value: JSON.stringify(data[key]),
            }
          : {
              Name: toSnakeCase(key),
              Value: data[key],
            };
      return new CognitoUserAttribute(userAttribute);
    });
};

const UserProvider = ({ location, history, children }) => {
  const [user, setUser] = useState(null);
  const [cognitoUser, setCognitoUser] = useState(null);
  const ldClient = useLDClient();

  const fetchAndRedirectUser = (cogUser) => {
    cogUser.getSession((err, session) => {
      if (err) {
        console.error(err.message || JSON.stringify(err));
        history.push('/welcome/back');
        return;
      }
      if (!session.isValid()) {
        console.log('invalid session');
        history.push('/welcome/back');
        return;
      }
      // sets the accessToken for the appsync client
      localStorage.setItem('accessToken', session.getIdToken().getJwtToken());
      // sets the currentUser sub for faster flag initilization on page refresh
      localStorage.setItem('currentUser', cogUser.username);
      ldClient.identify({ key: cogUser.username });
      setCognitoUser(cogUser);

      cogUser.getUserAttributes((e, attributes) => {
        if (e) {
          console.error(e.message || JSON.stringify(e));
          history.push('/welcome/back');
          return;
        }

        const authenticatedUser = attributes.reduce((userObj, attr) => {
          if (attr.Name.endsWith('verified')) {
            // eslint-disable-next-line no-param-reassign
            userObj[toCamel(attr.Name)] = attr.Value === 'true';
          } else {
            // eslint-disable-next-line no-param-reassign
            userObj[toCamel(attr.Name)] = attr.Value;
          }
          return userObj;
        }, {});

        setUser(authenticatedUser);
        delightedNpsSurvey(authenticatedUser);
        if (location.pathname.startsWith('/welcome')) {
          history.push('/');
        }
      });
    });
  };

  useEffect(() => {
    if (user) {
      return;
    }
    const cogUser = userPool.getCurrentUser();
    if (!cogUser) {
      if (!location.pathname.startsWith('/welcome')) {
        history.push('/welcome/back');
      }
      return;
    }

    ldClient.identify({ key: cogUser.username });
    fetchAndRedirectUser(cogUser);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onSignIn = ({ email, password }, isNewUser = false) => {
    const authenticationData = {
      Username: email,
      Password: password,
    };
    const authenticationDetails = new AuthenticationDetails(authenticationData);
    const userData = {
      Username: email,
      Pool: userPool,
    };
    const cogUser = new CognitoUser(userData);
    return new Promise((resolve, reject) => {
      cogUser.authenticateUser(authenticationDetails, {
        onSuccess: () => {
          if (isNewUser) {
            cogUser.getAttributeVerificationCode('email', {
              onSuccess: () => console.log('Verification code sent'),
              onFailure: (e) => console.log(e),
            });
          }
          fetchAndRedirectUser(cogUser);
          resolve();
        },
        onFailure: reject,
      });
    });
  };

  const onSignUp = (data) => {
    return new Promise((resolve, reject) => {
      const attributeList = fillUserAttributes(data);
      userPool.signUp(data.email, data.password, attributeList, null, (err) => {
        if (err) {
          reject(err);
          return;
        }
        // eslint-disable-next-line consistent-return
        return onSignIn({ email: data.email, password: data.password }, true)
          .then(resolve)
          .catch(reject);
      });
    });
  };

  const onSignOut = () => {
    const cogUser = userPool.getCurrentUser();
    cogUser.signOut();
    history.push('/welcome/back');
  };

  const sendEmailVerificationCode = (onSuccess, onFailure) => {
    cognitoUser.getAttributeVerificationCode('email', { onSuccess, onFailure });
  };

  const onVerifyEmail = (code, onSuccess, onFailure) => {
    cognitoUser.verifyAttribute('email', code, { onSuccess, onFailure });
  };

  const onUpdateUser = (data, callback) => {
    const attributes = fillUserAttributes(data);
    cognitoUser.updateAttributes(attributes, callback);

    cognitoUser.getSession((err, session) => {
      if (err) {
        console.error(err.message || JSON.stringify(err));
      }
      if (!session.isValid()) {
        console.log('invalid session');
      }
      // sets the accessToken for the appsync client
      localStorage.setItem('accessToken', session.getIdToken().getJwtToken());
    });
  };

  const changePassword = ({ password, newPassword }, callback) => {
    cognitoUser.changePassword(password, newPassword, callback);
  };

  const userContextValues = {
    onSignIn,
    onSignUp,
    onSignOut,
    onVerifyEmail,
    sendEmailVerificationCode,
    onUpdateUser,
    changePassword,
    user,
  };

  return (
    <UserContext.Provider value={userContextValues}>
      {children}
    </UserContext.Provider>
  );
};

UserProvider.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
  history: PropTypes.shape({
    push: PropTypes.func.isRequired,
  }).isRequired,
  location: PropTypes.shape({
    pathname: PropTypes.string.isRequired,
  }).isRequired,
};

UserProvider.displayName = 'UserProvider';

export * from './context';
export default withRouter(UserProvider);
