export const validations = {
  appName: {
    required: {
      value: true,
      message: 'App name is required',
    },
  },
  appUrl: {
    required: {
      value: true,
      message: 'App URL is required',
    },
    urls: {
      value: true,
      message: 'Invalid URL format',
    },
  },
  redirectUris: {
    required: {
      value: true,
      message: 'Callback URLs are required',
    },
    urls: {
      value: true,
      message: 'Invalid URL format',
    },
  },
};
