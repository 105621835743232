import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import {
  Col,
  Icon,
  Label,
  FilestackInlineUploader,
  Notification,
} from '@thinkific/toga-react';
import { Card } from '../../Card';
import styles from './ThemeExtensions.module.scss';
import { updateThemeExtensionApp } from './handlers';
import { apolloClient } from '../../../config/apollo-client';
import { useUiContext } from '../../UiContext';
import { graphqlMutationErrors } from '../../../utils/errors';

const DOCUMENTATION_URN =
  'https://developers.thinkific.com/building-apps/theme-extensions';
const FILESTACK_CDN_URL = 'https://cdn.filestackcontent.com/';
const GUIDELINES_URN = `${DOCUMENTATION_URN}#extension-guidelines`;
const VERSIONING_INFO_URN = `${DOCUMENTATION_URN}#app-and-extension-versioning`;
const MAX_FILE_SIZE_IN_MB = 25;
const MAX_FILE_SIZE_IN_BYTES = MAX_FILE_SIZE_IN_MB * 1024 * 1024; // 25MB

const ThemeExtensions = ({ id, appData, onUpload, clientOptions }) => {
  const [hasSections, setHasSections] = useState(false);
  const [hasSnippets, setHasSnippets] = useState(false);
  const [fileData, setFileData] = useState(null);
  const [uploadStatus, setUploadStatus] = useState(null);
  const { showToast } = useUiContext();

  const handleFileUpload = (filesUploaded) => {
    const file = filesUploaded[0];
    const securityParams = `security=policy:${clientOptions.security.policy},signature:${clientOptions.security.signature}/`;
    const url = FILESTACK_CDN_URL + securityParams + file.handle;

    setFileData({ client: apolloClient, url, appId: id });
  };

  const onFileSelected = (file) => {
    // The error that is thrown will be displayed to the user as an alert:
    // https://filestack.github.io/filestack-js/interfaces/pickeroptions.html#onfileselected
    if (file.size > MAX_FILE_SIZE_IN_BYTES) {
      throw new Error(
        `File size exceeds max of ${MAX_FILE_SIZE_IN_MB}MB. Please select a smaller file.`
      );
    }
  };

  const filestackOptions = {
    maxSize: MAX_FILE_SIZE_IN_BYTES,
    onFileSelected,
  };

  useEffect(() => {
    if (fileData) {
      const callUpdate = async () => {
        try {
          const res = await updateThemeExtensionApp(fileData);
          setUploadStatus(res);
          onUpload();
        } catch (e) {
          showToast('alert', graphqlMutationErrors(e));
        }
      };

      callUpdate();
    }
  }, [fileData, onUpload]);

  useEffect(() => {
    if (appData) {
      appData.oauth_app_views.forEach((el) => {
        if (el.view_type === 'section') setHasSections(true);
        if (el.view_type === 'snippet') setHasSnippets(true);
      });
    }
  }, [appData]);

  useEffect(() => {
    if (uploadStatus && uploadStatus.success)
      showToast('notice', 'Extension file uploaded successfully');
  }, [showToast, uploadStatus]);

  const SectionInfo = () => (
    <>
      <h4 className={`table-container__title ${styles.sections__title}`}>
        Sections
      </h4>

      <table className={`table table-minimal ${styles.sections__table || ''}`}>
        <thead>
          <tr>
            <th>Name</th>
            <th>Location</th>
          </tr>
        </thead>
        <tbody>
          {appData.oauth_app_views.map((el) => {
            return (
              el.view_type === 'section' && (
                <tr>
                  <td>{el.name}</td>
                  <td>{el.location || 'All pages'}</td>
                </tr>
              )
            );
          })}
        </tbody>
      </table>
    </>
  );

  const SnippetInfo = () => (
    <>
      <h4 className={`table-container__title ${styles.snippets__title}`}>
        Snippets
      </h4>
      <ul className={styles.snippets__list}>
        {appData.oauth_app_views.map((el) => {
          return (
            el.view_type === 'snippet' && (
              <li className={styles.snippets__listitem}>
                <Icon name="document" /> {el.name}
              </li>
            )
          );
        })}
      </ul>
    </>
  );

  const UploadErrors = () => {
    // Errors can be either in the format: {errors: {<name>: [<error-messages>]}} or {error: <error-message>}
    const errors = uploadStatus.errors ? (
      Object.entries(uploadStatus.errors).map(([k, v]) => {
        return v.map((e) => (
          <li key={`${k}-${e}`}>
            {k}: {e}
          </li>
        ));
      })
    ) : (
      <li>{uploadStatus.error}</li>
    );

    return (
      <Notification
        className={styles.infobox__alert}
        type="danger"
        hasCloseButton
        onClose={() => setUploadStatus(null)}
      >
        <strong>There were errors processing your zip file</strong>
        <ul data-qa-notification--errors>{errors}</ul>
        Read our{' '}
        <a href={GUIDELINES_URN} rel="noopener noreferrer" target="_blank">
          extension guidelines
        </a>{' '}
        for more information.
      </Notification>
    );
  };

  const UploadWarnings = () => {
    if (
      !uploadStatus ||
      !uploadStatus.warnings ||
      Object.keys(uploadStatus.warnings).length < 1
    ) {
      return null;
    }

    const warnings =
      uploadStatus.warnings &&
      Object.entries(uploadStatus.warnings).map(([k, v]) => {
        return (
          <li key={`${k}-${v}`}>
            {k}: {v}
          </li>
        );
      });

    return (
      <Notification className={styles.infobox__alert} type="warning">
        <strong>There were warnings while processing your zip file</strong>
        <ul data-qa-notification--warnings>{warnings}</ul>
        Read our{' '}
        <a href={VERSIONING_INFO_URN} rel="noopener noreferrer" target="_blank">
          extension documentation
        </a>{' '}
        for more information.
      </Notification>
    );
  };

  return (
    <Card title="Theme Extensions" beta>
      <div className={styles.container}>
        <Col>
          <p>
            Supercharge Thinkific websites by integrating your app&apos;s
            functionality into sitebuilder with custom pages or sections.{' '}
            <a
              href={DOCUMENTATION_URN}
              rel="noopener noreferrer"
              target="_blank"
            >
              Learn about theme extensions
            </a>
          </p>
          <br />
          <Label htmlFor="infobox__upload">Upload zip file</Label>
          <div className={styles.infobox__upload} id="infobox__upload">
            <FilestackInlineUploader
              awsBucket={process.env.REACT_APP_FILESTACK_UPLOAD_BUCKET}
              awsPath={process.env.REACT_APP_FILESTACK_UPLOAD_BUCKET_PATH}
              awsRegion={process.env.REACT_APP_FILESTACK_UPLOAD_BUCKET_REGION}
              clientOptions={clientOptions}
              filestackAPIKey={process.env.REACT_APP_FILESTACK_API_KEY}
              filestackOptions={filestackOptions}
              hasUploadedFileDisplayed={false}
              isDisabled={false}
              isFileProcessingState={false}
              uploadedFilename={null}
              // eslint-disable-next-line no-alert
              onError={() => alert('Error')}
              onSuccess={(filesUploaded) => handleFileUpload(filesUploaded)}
            />
          </div>
          <small>
            Files will be processed and stored within your app as an extension.
          </small>
          {uploadStatus && !uploadStatus.success && <UploadErrors />}
          {appData.oauth_app_views[0] && (
            <div className={`table-container ${styles.infobox}`}>
              {hasSections && <SectionInfo />}
              {hasSnippets && <SnippetInfo />}
            </div>
          )}
          <UploadWarnings />
        </Col>
      </div>
    </Card>
  );
};

ThemeExtensions.propTypes = {
  appData: PropTypes.shape({
    /* eslint-disable-next-line */
    oauth_app_views: PropTypes.array,
  }),
  clientOptions: PropTypes.object.isRequired,
  id: PropTypes.string.isRequired,
  onUpload: PropTypes.func.isRequired,
};

ThemeExtensions.defaultProps = {
  appData: { oauth_app_views: [] },
};

export default ThemeExtensions;
