import Accesslink from '../../../../graphql/Mutation/Accesslink';
import DisconnectClientSite from '../../../../graphql/Mutation/DisconnectClientSite';

export const getAccessLinkUrl = async (tenantGlobalId, client) => {
  const result = await client.mutate({
    variables: { tenantGlobalId },
    mutation: Accesslink,
  });
  if (result.errors && result.errors.length) {
    throw new Error('Something went wrong when requesting access.');
  }
  return result.data.accessLink.link;
};

export const authenticateUser = (link) => {
  window.open(link, '_blank');
};

export const disconnectSite = async (tenantGlobalId, client) => {
  const result = await client.mutate({
    variables: { id: tenantGlobalId },
    mutation: DisconnectClientSite,
  });
  if (result.errors && result.errors.length) {
    throw new Error('Something went wrong when disconnecting the site.');
  }
  return result.data;
};
