import gql from 'graphql-tag';

const AppDetailsQuery = gql`
  query App($id: Int) {
    app(id: $id) {
      id
      slug
      name
      description
      logo
      client_id
      client_secret
      url
      redirect_uris
      scopes
      partner_id
      support_email
      support_contact_form_url
      support_doc_link
      author
      api_contact_email
      website
      oauth_app_views {
        name
        view_type
      }
      extensions {
        app_settings
        communities_background
        course_player_background
        performance_checkout_background
        theme_background
      }
    }
  }
`;

export default AppDetailsQuery;
