import { useLocation, useRouteMatch } from 'react-router-dom';
import ROUTES from '../../utils/routeConstants';
import { newsPath, appsPath, indexPath } from '../../utils/routes';
import { CONNECT_CLIENT_SITE, OPEN_DOCUMENTATION } from './constants';

const defaultState = {
  breadCrumbLabel: '',
  breadCrumbPath: '/',
  hasAction: false,
  hasBackground: true,
  hasBreadcrumb: false,
  hasTitle: true,
  title: ROUTES.INDEX.TITLE,
};

const pageDetails = {
  default: defaultState,
  [ROUTES.INDEX.PATH]: {
    ...defaultState,
    title: ROUTES.INDEX.TITLE,
  },
  [ROUTES.NEWS.PATH]: {
    ...defaultState,
    breadCrumbLabel: 'Learn & Connect',
    breadCrumbPath: indexPath(),
    hasBreadcrumb: true,
    singleColumn: true,
    title: ROUTES.NEWS.TITLE,
  },
  [ROUTES.SITES.PATH]: {
    ...defaultState,
    singleColumn: true,
    title: ROUTES.SITES.TITLE,
  },
  [ROUTES.SITES_MANAGED.PATH]: {
    ...defaultState,
    action: CONNECT_CLIENT_SITE,
    hasAction: true,
    title: ROUTES.SITES_MANAGED.TITLE,
  },
  [ROUTES.SITES_DEVELOPMENT.PATH]: {
    ...defaultState,
    singleColumn: true,
    title: ROUTES.SITES_DEVELOPMENT.TITLE,
  },
  [ROUTES.APPS.PATH]: {
    ...defaultState,
    action: OPEN_DOCUMENTATION,
    hasAction: true,
    title: ROUTES.APPS.TITLE,
  },
  [ROUTES.APP_CREATE.PATH]: {
    ...defaultState,
    action: OPEN_DOCUMENTATION,
    hasAction: true,
    title: ROUTES.APPS.TITLE,
  },
  [ROUTES.CALLBACK.PATH]: {
    ...defaultState,
    title: '',
  },
  [ROUTES.APP_EDIT.PATH]: {
    ...defaultState,
    breadCrumbLabel: 'Apps',
    breadCrumbPath: appsPath(),
    hasBackground: false,
    hasBreadcrumb: true,
    singleColumn: true,
    title: ROUTES.APPS.TITLE,
  },
  [ROUTES.ACCOUNT.PATH]: {
    ...defaultState,
    singleColumn: true,
    title: ROUTES.ACCOUNT.TITLE,
  },
  [ROUTES.NEWS_DETAILS.PATH]: {
    ...defaultState,
    breadCrumbLabel: 'News',
    breadCrumbPath: newsPath(),
    hasBackground: false,
    hasBreadcrumb: true,
    hasTitle: false,
    singleColumn: true,
  },
  [ROUTES.ROADMAP.PATH]: {
    ...defaultState,
    title: ROUTES.ROADMAP.TITLE,
  },
};

const pageDetailsDefinitions = (defaultDetails) => (key) => {
  return Object.prototype.hasOwnProperty.call(pageDetails, key)
    ? { ...pageDetails[key], ...defaultDetails }
    : { ...defaultDetails, ...pageDetails.default };
};

function usePathMatch() {
  const { pathname } = useLocation();
  const newsDetailRouteMatch = useRouteMatch('/news/:slug')
    ? ROUTES.NEWS_DETAILS.PATH
    : null;
  const appDetailRouteMatch = useRouteMatch('/apps/:id/edit')
    ? ROUTES.APP_EDIT.PATH
    : null;
  return newsDetailRouteMatch || appDetailRouteMatch || pathname;
}

export default { pageDetailsDefinitions, usePathMatch };
