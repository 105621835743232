import { INVALID_URLS } from './messages';

const isValidListOfUrls = (stringifiedUrls) => {
  const lineBreakRegExpRule = /\r\n|\r|\n/;
  const validUrlRegExpRule = /^(?:[a-zA-Z][a-zA-Z\d+\-.]*):\/\/+/;

  const urls = stringifiedUrls.split(lineBreakRegExpRule);
  const listOfInvalidUrls = urls.filter(
    (url) => !url.match(validUrlRegExpRule) && url
  );
  return listOfInvalidUrls.length === 0;
};

const urlsValidation = (fields, key, criteria) => {
  const message = criteria.message || INVALID_URLS;

  if (!fields[key]) {
    return null;
  }

  const field = Array.isArray(fields[key])
    ? fields[key].join('\n')
    : fields[key];
  return isValidListOfUrls(field) ? null : message;
};
export default urlsValidation;
