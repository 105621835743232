import React from 'react';
import { TYPE_FORM_MODAL, CONNECT_CLIENT_SITE_MODAL } from './constants';
import TypeformModal from './TypeformModal';
import { ConnectClientSiteModal } from '../Sites';
import { useUiContext } from '../UiContext';

const modalTypes = {
  [TYPE_FORM_MODAL]: TypeformModal,
  [CONNECT_CLIENT_SITE_MODAL]: ConnectClientSiteModal,
};

const Modal = () => {
  const { modalInstance } = useUiContext();
  const ModalComponent = modalTypes[modalInstance.type] || null;

  return <ModalComponent {...modalInstance} />;
};

Modal.displayName = 'Modal';

export default Modal;
