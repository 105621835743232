import React from 'react';
import PropTypes from 'prop-types';
import { Tabs } from '@thinkific/toga-react';

const AppTabs = ({ tabs }) => (
  <Tabs
    accesibleLabel="App tabs"
    tabItems={tabs.map((item) => ({
      id: item.id,
      title: item.title,
      content: item.component(),
    }))}
  />
);

AppTabs.propTypes = {
  tabs: PropTypes.arrayOf(
    PropTypes.shape({
      content: PropTypes.func,
      id: PropTypes.string,
      title: PropTypes.string,
    })
  ).isRequired,
};

export default AppTabs;
