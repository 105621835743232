import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import {
  Button,
  Input,
  Modal,
  ModalBody,
  ModalFooter,
} from '@thinkific/toga-react';
import { useUserContext } from '../UserProvider';
import { useUiContext } from '../UiContext';
import styles from './EmailValidationAlert.module.scss';

export function EmailValidationAlert() {
  const history = useHistory();
  const { user, onVerifyEmail, sendEmailVerificationCode } = useUserContext();
  const { showToast } = useUiContext();
  const [isOpen, setIsOpen] = useState(false);
  const [verificationCode, setVerificationCode] = useState('');

  function handleSendCode() {
    sendEmailVerificationCode(
      () => showToast('notice', `Verification code sent to ${user.email}`),
      (e) => console.error(e)
    );
  }

  function verifyEmail() {
    onVerifyEmail(
      verificationCode,
      () => {
        setIsOpen(false);
        history.go(0);
      },
      (e) => console.error(e)
    );
  }

  return (
    <>
      <div className={styles.alert}>
        <p className={styles.message}>
          Please &nbsp;{' '}
          <span
            className={styles['verify-button']}
            role="button"
            tabIndex={0}
            onClick={() => setIsOpen(true)}
            onKeyPress={() => setIsOpen(true)}
          >
            verify email
          </span>{' '}
          &nbsp; to access all features.
        </p>
      </div>
      <Modal
        closeModal={() => setIsOpen(false)}
        isOpen={isOpen}
        style={{ content: { width: 480 } }}
        title="Enter email verification code"
      >
        <ModalBody>
          <p>
            We have sent a verification code to <i>{user.email}</i>, please
            enter the code you received below:
          </p>
          <br />
          <Input
            handleChange={(e) => setVerificationCode(e.target.value)}
            initValue={verificationCode}
            name="verificationCode"
            type="text"
            onEnter={verifyEmail}
          />
        </ModalBody>
        <ModalFooter>
          <Button appearance="secondary" onClick={handleSendCode}>
            Resend Code
          </Button>
          <Button appearance="primary" onClick={verifyEmail}>
            Submit Code
          </Button>
        </ModalFooter>
      </Modal>
    </>
  );
}

EmailValidationAlert.displayName = 'EmailValidationAlert';
