import React, { Children } from 'react';
import PropTypes from 'prop-types';
import styles from './Sidebar.module.scss';

const SidebarFooter = ({ children }) => {
  return (
    <nav className="sidebar__footer-navigation">
      <ul className="sidebar__footer-list">
        {Children.map(children, (child) => (
          <li
            className={`sidebar__footer-list-item ${styles['footer-list-item']}`}
          >
            {child}
          </li>
        ))}
      </ul>
    </nav>
  );
};

SidebarFooter.defaultProps = {};

SidebarFooter.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
};

SidebarFooter.displayName = 'SidebarFooter';

export default SidebarFooter;
