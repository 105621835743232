import React, {
  useReducer,
  useState,
  useLayoutEffect,
  useRef,
  useEffect,
} from 'react';
import PropTypes from 'prop-types';
import Input from '@thinkific/toga-input';
import { NavLink } from 'react-router-dom';
import Checkbox from '@thinkific/toga-checkbox';
import LoadingButton from '@thinkific/toga-loading-button';
import InlineAlert from '../../InlineAlert';
import loadRecaptcha from '../../../utils/loadRecaptcha';
import {
  validateFields,
  isValid,
  DEFAULT_ERRORS_CLASSNAME,
} from '../../../utils/validations';
import validations from './validations';
import styles from './PartnerAccountInfo.module.scss';
import { PASSWORD_CAPTION } from '../../../utils/contants';

const initialState = {
  givenName: '',
  familyName: '',
  email: '',
  password: '',
  passwordConfirmation: '',
  termsAndConditions: false,
};

const reducer = (state, action) => {
  const { key, value } = action;
  return {
    ...state,
    [key]: value,
  };
};

const PartnerAccountInfo = ({
  onSubmit,
  serverError,
  emailError,
  email: receivedEmail,
}) => {
  const [state, dispatch] = useReducer(reducer, {
    ...initialState,
    email: receivedEmail,
  });
  const [saving, setSaving] = useState(false);
  const [recaptchaValidated, setRecaptchaValidated] = useState(true);
  const [captchaLoaded, setCaptchaLoaded] = useState(false);
  const [validationErrors, setValidationErrors] = useState({});
  const recaptchaEl = useRef(null);

  const {
    givenName,
    familyName,
    email,
    password,
    passwordConfirmation,
    termsAndConditions,
  } = state;

  const verifyCallback = () => {
    setRecaptchaValidated(true);
  };

  useLayoutEffect(() => {
    if (!captchaLoaded) {
      loadRecaptcha(recaptchaEl, verifyCallback);
      setCaptchaLoaded(true);
    }
  }, [captchaLoaded]);

  useEffect(() => {
    if (serverError || emailError) {
      setSaving(false);
    }
  }, [serverError, emailError]);

  const handleOnSubmit = () => {
    const lastErrors = validateFields(state, validations);
    setValidationErrors(lastErrors);

    if (isValid(lastErrors) && recaptchaValidated) {
      setSaving(true);
      onSubmit(state);
    }
  };

  return (
    <>
      <div className="Welcome__Header">
        <h1>Create your partner account</h1>
      </div>
      <div className="Welcome__Surface mb-5">
        {serverError && (
          <InlineAlert message={serverError} type="danger" isFirstElement />
        )}
        <div className="form-row">
          <div className="form-group col-md-6">
            <Input
              extraClasses={validationErrors.givenName ? 'is-invalid' : ''}
              extraProps={{ disabled: saving }}
              handleChange={(e) =>
                dispatch({ key: 'givenName', value: e.target.value })
              }
              id="givenName"
              initValue={givenName}
              label="First Name"
              maxLength={255}
              name="givenName"
              placeholder="First Name"
              type="text"
            />
            {validationErrors.givenName &&
              validationErrors.givenName.required && (
                <span className={DEFAULT_ERRORS_CLASSNAME}>
                  {validationErrors.givenName.required}
                </span>
              )}
          </div>

          <div className="form-group col-md-6">
            <Input
              extraClasses={validationErrors.familyName ? 'is-invalid' : ''}
              extraProps={{ disabled: saving }}
              handleChange={(e) =>
                dispatch({ key: 'familyName', value: e.target.value })
              }
              id="familyName"
              initValue={familyName}
              label="Last Name"
              maxLength={255}
              name="familyName"
              placeholder="Last Name"
              type="text"
            />
            {validationErrors.familyName &&
              validationErrors.familyName.required && (
                <span className={DEFAULT_ERRORS_CLASSNAME}>
                  {validationErrors.familyName.required}
                </span>
              )}
          </div>
        </div>
        <div className="form-row">
          <div className="form-group col-md-12">
            <Input
              extraClasses={
                validationErrors.email || emailError ? 'is-invalid' : ''
              }
              extraProps={{ disabled: saving }}
              handleChange={(e) =>
                dispatch({ key: 'email', value: e.target.value })
              }
              id="email"
              initValue={email}
              label="Email"
              name="email"
              placeholder="name@company.com"
              type="email"
            />
            {validationErrors.email && validationErrors.email.email && (
              <span className={DEFAULT_ERRORS_CLASSNAME}>
                {validationErrors.email.email}
              </span>
            )}
            {!validationErrors.email && emailError && (
              <span className={DEFAULT_ERRORS_CLASSNAME}>{emailError}</span>
            )}
          </div>
        </div>
        <div className="form-row">
          <div className="form-group col-md-12">
            <Input
              extraClasses={validationErrors.password ? 'is-invalid' : ''}
              extraProps={{ disabled: saving }}
              formText={validationErrors.password ? '' : PASSWORD_CAPTION}
              handleChange={(e) =>
                dispatch({ key: 'password', value: e.target.value })
              }
              id="password"
              initValue={password}
              label="Password"
              name="password"
              type="password"
            />
            {validationErrors.password && (
              <span className={DEFAULT_ERRORS_CLASSNAME}>
                {PASSWORD_CAPTION}
              </span>
            )}
          </div>
        </div>
        <div className="form-row">
          <div className="form-group col-md-12">
            <Input
              extraClasses={
                validationErrors.passwordConfirmation ? 'is-invalid' : ''
              }
              extraProps={{ disabled: saving }}
              handleChange={(e) =>
                dispatch({ key: 'passwordConfirmation', value: e.target.value })
              }
              id="passwordConfirmation"
              initValue={passwordConfirmation}
              label="Confirm password"
              name="passwordConfirmation"
              type="password"
            />
            {validationErrors.passwordConfirmation &&
              validationErrors.passwordConfirmation.match && (
                <span className={DEFAULT_ERRORS_CLASSNAME}>
                  {validationErrors.passwordConfirmation.match}
                </span>
              )}
          </div>
        </div>
        <div className="form-row">
          <div className="form-group col-md-12">
            <span style={{ display: 'flex' }}>
              <Checkbox
                id="termsAndConditions"
                isChecked={termsAndConditions}
                labelText={
                  <>
                    I have read and agreed to the Thinkific Partner
                    Program&nbsp;
                    <a
                      href="https://www.thinkific.com/terms-of-service/"
                      rel="noreferrer noopener"
                      target="_blank"
                    >
                      terms and conditions
                    </a>
                  </>
                }
                name="termsAndConditions"
                onChange={() =>
                  dispatch({
                    key: 'termsAndConditions',
                    value: !termsAndConditions,
                  })
                }
              />
            </span>
            {validationErrors.termsAndConditions &&
              validationErrors.termsAndConditions.required && (
                <div className={styles.agreement__error}>
                  <span className={DEFAULT_ERRORS_CLASSNAME}>
                    {validationErrors.termsAndConditions.required}
                  </span>
                </div>
              )}
          </div>
        </div>

        <div className="form-row" ref={recaptchaEl} />

        <hr />
        <div className="d-md-flex justify-content-between mt-4 align-items-center">
          <div>
            <small>Already have a partner account?&nbsp;</small>
            <NavLink aria-expanded="false" to="/welcome/back" exact>
              Log in
            </NavLink>
          </div>
          <LoadingButton
            appearance="primary"
            disabled={false}
            hasSuccessDisabled={false}
            id="createAndContinue"
            isLoading={saving}
            onClick={handleOnSubmit}
          >
            CREATE
          </LoadingButton>
        </div>
      </div>
    </>
  );
};

PartnerAccountInfo.defaultProps = {
  email: '',
  emailError: null,
  serverError: null,
};

PartnerAccountInfo.propTypes = {
  email: PropTypes.string,
  emailError: PropTypes.string,
  serverError: PropTypes.string,
  onSubmit: PropTypes.func.isRequired,
};

PartnerAccountInfo.displayName = 'PartnerAccountInfo';

export default PartnerAccountInfo;
