import gql from 'graphql-tag';

const updateThemeExtension = gql`
  mutation installExtensionPackage($id: ID!, $url: String) {
    installExtensionPackage(id: $id, url: $url) {
      oauth_app_views {
        name
        view_type
      }
      warnings
    }
  }
`;

export default updateThemeExtension;
