import React from 'react';
import PropTypes from 'prop-types';
import styles from './Sidebar.module.scss';

const SidebarLink = ({ children }) => {
  const extraProps = {
    className: `${children.className || ''} sidebar__link`,
  };

  if (!(typeof children.type === 'string')) {
    extraProps.activeClassName = styles['is-active'];
  }

  return React.cloneElement(children, extraProps);
};

SidebarLink.defaultProps = {};

SidebarLink.propTypes = {
  children: PropTypes.node.isRequired,
};

SidebarLink.displayName = 'SidebarLink';

export default SidebarLink;
