import React from 'react';
import { useHistory } from 'react-router-dom';
import { Button } from '@thinkific/toga-react';
import { MESSAGES, TYPES } from '../constants';
import InlineAlert from '../../../InlineAlert';

const SuccessAlert = () => {
  const history = useHistory();

  return (
    <>
      <InlineAlert message={MESSAGES.SUCCESS} type={TYPES.SUCCESS} />
      <div className="d-md-flex justify-content-end align-items-center">
        <Button onClick={() => history.push('/welcome/back')}>
          Go to login
        </Button>
      </div>
    </>
  );
};

SuccessAlert.displayName = 'SuccessAlert';

export default SuccessAlert;
