import React from 'react';
import PropTypes from 'prop-types';
import { Textarea } from '@thinkific/toga-react';
import getErrorMessage from './getErrorMessage';

const ValidatedTextarea = ({ fieldErrors, ...props }) => {
  const errorMessage = getErrorMessage(fieldErrors);

  const errorProps = {
    ...(errorMessage && {
      formText: errorMessage,
      isinvalid: 'true',
      hasError: true,
    }),
  };

  return <Textarea {...props} {...errorProps} />;
};

ValidatedTextarea.propTypes = {
  ...Textarea.propTypes,
  fieldErrors: PropTypes.object,
};

ValidatedTextarea.defaultProps = {
  fieldErrors: null,
};

export default ValidatedTextarea;
