import React from 'react';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import TagManager from 'react-gtm-module';
import { ApolloProvider } from 'react-apollo';
import { apolloClient } from './config/apollo-client';
import Welcome from './components/Welcome';
import Portal from './components/Portal';
import UserProvider from './components/UserProvider';
import { UiContextProvider } from './components/UiContext';
import AppcuesTracker from './components/AppcuesTracker/AppcuesTracker';
import FrameKiller from './utils/frameKiller';

import './App.scss';

TagManager.initialize({ gtmId: 'GTM-N2TBDQ2' });

function App() {
  FrameKiller();

  return (
    <ApolloProvider client={apolloClient}>
      <Router>
        <AppcuesTracker>
          <UiContextProvider>
            <UserProvider>
              <Switch>
                <Route component={Welcome} path="/welcome" />
                <Route component={Portal} path="/" />
              </Switch>
            </UserProvider>
          </UiContextProvider>
        </AppcuesTracker>
      </Router>
    </ApolloProvider>
  );
}

App.displayName = 'App';

export default App;
