import React from 'react';
import Button, { withIcon } from '@thinkific/toga-button';
import Icons from '../../Icons';
import { DOCUMENTATION_LINK } from '../../Portal/constants';
import { useUiContext, OPEN_DOCUMENTATION } from '../../UiContext';
import { CONNECT_CLIENT_SITE_MODAL } from '../../Modal';
import { e2eId } from '../../../utils/test.utils';

const ButtonWithIconLeft = withIcon({ position: 'left' })(Button);
const ButtonWithIconRight = withIcon({ position: 'right' })(Button);

const HeaderAction = () => {
  const {
    pageProps: { action },
    launchModal,
  } = useUiContext();

  if (action === OPEN_DOCUMENTATION) {
    return (
      <ButtonWithIconRight
        appearance="secondary"
        href={DOCUMENTATION_LINK}
        target="_blank"
        isDark
      >
        Documentation
        <i className="toga-icon toga-icon-arrow-up-right" />
      </ButtonWithIconRight>
    );
  }

  return (
    <ButtonWithIconLeft
      appearance="secondary"
      isDark
      onClick={() => {
        launchModal({
          type: CONNECT_CLIENT_SITE_MODAL,
        });
      }}
      {...e2eId('openConnectClientSiteModalFromHeaderAction')}
    >
      <Icons.Plus name="plus" />
      Connect Site
    </ButtonWithIconLeft>
  );
};

export default HeaderAction;
