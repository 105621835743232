import gql from 'graphql-tag';

const AppDetailMutation = gql`
  mutation UpdateApp(
    $id: ID!
    $slug: String!
    $name: String!
    $description: String!
    $logo: String
    $url: String!
    $redirect_uris: [String]!
    $support_email: String!
    $support_contact_form_url: String
    $support_doc_link: String!
    $api_contact_email: String
    $website: String
    $author: String!
    $extensions: AppExtensionsInput!
  ) {
    updateApp(
      id: $id
      input: {
        slug: $slug
        name: $name
        description: $description
        logo: $logo
        url: $url
        redirect_uris: $redirect_uris
        support_email: $support_email
        support_contact_form_url: $support_contact_form_url
        support_doc_link: $support_doc_link
        author: $author
        api_contact_email: $api_contact_email
        website: $website
        extensions: $extensions
      }
    ) {
      id
      slug
      name
      description
      logo
      url
      redirect_uris
      scopes
      support_email
      support_contact_form_url
      support_doc_link
      author
      api_contact_email
      website
      extensions {
        app_settings
        communities_background
        course_player_background
        performance_checkout_background
        theme_background
      }
    }
  }
`;

export default AppDetailMutation;
