import React, { Children } from 'react';
import PropTypes from 'prop-types';
import styles from './SidebarMenuItem.module.scss';
import { useSidebarContext } from './SidebarContext';

const SidebarMenuItem = ({ title, icon, menuKey, children, ...props }) => {
  const { activeMenuItem, toggleMenuItem } = useSidebarContext();
  const showMenu = activeMenuItem === menuKey;

  return (
    <>
      <button
        className={`sidebar__link ${styles.toggle}`}
        id="toogleAccountMenu"
        type="button"
        onClick={() =>
          showMenu ? toggleMenuItem(null) : toggleMenuItem(menuKey)
        }
        {...props}
      >
        {icon && (
          <i
            className={`sidebar__icon toga-product-icon toga-product-icon-${icon}`}
          />
        )}
        {title}
      </button>
      {showMenu && (
        <div className={styles.submenu}>
          <ul className="sidebar__list">
            {Children.map(children, (child) => (
              <li className="sidebar__list-item">{child}</li>
            ))}
          </ul>
        </div>
      )}
    </>
  );
};

SidebarMenuItem.defaultProps = {
  title: null,
  icon: null,
};

SidebarMenuItem.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
  icon: PropTypes.string,
  menuKey: PropTypes.string.isRequired,
  title: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
};

SidebarMenuItem.displayName = 'SidebarMenuItem';

export default SidebarMenuItem;
