import React from 'react';
import { Card } from '../Card';
import styles from './Roadmap.module.scss';

const Roadmap = () => {
  return (
    <Card title="">
      <div className={styles.container}>
        <iframe
          frameBorder="0"
          src="https://portal.productboard.com/hucz9piwngojj2x194kcavwk"
          title="Short-Term Roadmap & Feedback"
        />
      </div>
    </Card>
  );
};

export default Roadmap;
