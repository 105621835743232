import { useFlags, asyncWithLDProvider } from 'launchdarkly-react-client-sdk';

const clientSideID = process.env.REACT_APP_LAUNCH_DARKLY_ID;
const initializeFlagProvider = async () => {
  const key = localStorage.getItem('currentUser');
  const user = key !== null ? { key } : undefined;
  return asyncWithLDProvider({
    clientSideID,
    user,
  });
};

export { useFlags, initializeFlagProvider };
