/* eslint-disable react/no-unescaped-entities */
import React from 'react';
import { NavLink } from 'react-router-dom';
import PropTypes from 'prop-types';
import Icons from '../Icons';
import { useUiContext } from '../UiContext';
import styles from './Sidebar.module.scss';
import { e2eId } from '../../utils/test.utils';
import {
  appsPath,
  sitesPath,
  sitesDevelopmentPath,
  sitesManagedPath,
  roadmapPath,
} from '../../utils/routes';
import { useUserContext } from '../UserProvider';
import { useFlags } from '../../utils/flags';
import SidebarContainer from './SidebarContainer';
import SidebarHeader from './SidebarHeader';
import SidebarFooter from './SidebarFooter';
import SidebarMenuItem from './SidebarMenuItem';
import SidebarLink from './SidebarLink';

const Sidebar = ({ shrunk }) => {
  const { onSignOut, user } = useUserContext();
  const { isSidebarOpen, toggleSidebar } = useUiContext();
  const flags = useFlags();

  return (
    <SidebarContainer isOpen={isSidebarOpen} shrunk={shrunk}>
      <SidebarHeader toggleSidebar={toggleSidebar}>
        <NavLink aria-expanded="false" className="header__logo" to="/">
          <Icons.ThinkificLogo
            alt="Thinkific"
            className={styles['thinkific-logo']}
            name="Thinkific"
          />
        </NavLink>
      </SidebarHeader>

      <SidebarLink>
        <NavLink to="/" exact>
          <i className="sidebar__icon toga-product-icon toga-product-icon-flagship" />
          LEARN & CONNECT
        </NavLink>
      </SidebarLink>

      {!flags.newSitesNav && (
        <SidebarLink>
          <NavLink to={sitesPath()} exact {...e2eId('sidebarLinkSites')}>
            <i className="sidebar__icon toga-product-icon toga-product-icon-design" />
            SITES
          </NavLink>
        </SidebarLink>
      )}

      {flags.newSitesNav && (
        <SidebarMenuItem
          icon="design"
          menuKey="sites"
          title="SITES"
          {...e2eId('sidebarMenuSites')}
        >
          <NavLink
            to={sitesManagedPath()}
            exact
            {...e2eId('sidebarMenuLinkManagedSites')}
          >
            Managed
          </NavLink>
          <NavLink
            to={sitesDevelopmentPath()}
            exact
            {...e2eId('sidebarMenuLinkDevelopmentSites')}
          >
            Development
          </NavLink>
        </SidebarMenuItem>
      )}

      <SidebarLink>
        <NavLink
          aria-expanded="false"
          to={appsPath()}
          exact
          {...e2eId('menuItemApps')}
        >
          {/* Warning: when updating the toga icon version make sure to rename toga-icon-integration to toga-icon-apps */}
          <i className="sidebar__icon toga-icon toga-icon-integration" />
          APPS
        </NavLink>
      </SidebarLink>
      {flags.featurePartnerPortalRoadmap && (
        <SidebarLink>
          <NavLink
            aria-expanded="false"
            to={roadmapPath()}
            exact
            {...e2eId('sidebarMenuLinkRoadmap')}
          >
            <i className="sidebar__icon toga-product-icon toga-product-icon-microphone" />
            ROADMAP & FEEDBACK
          </NavLink>
        </SidebarLink>
      )}

      <SidebarLink>
        <a
          className="sidebar__link mt-3"
          href="https://app.partnerstack.com/"
          rel="noreferrer noopener"
          target="_blank"
        >
          <i className="sidebar__icon toga-product-icon toga-product-icon-handshake" />
          Referrals
          <i className="toga-icon toga-icon-arrow-up-right" />
        </a>
      </SidebarLink>

      <SidebarFooter>
        <SidebarMenuItem
          icon="account"
          menuKey="account"
          title={user && <span>{user.givenName}'s account</span>}
        >
          <NavLink to="/account" exact>
            Account details
          </NavLink>
          <a href="#!" onClick={() => onSignOut()}>
            Logout
          </a>
        </SidebarMenuItem>
      </SidebarFooter>
    </SidebarContainer>
  );
};

Sidebar.defaultProps = {
  shrunk: false,
};

Sidebar.propTypes = {
  shrunk: PropTypes.bool,
};

Sidebar.displayName = 'Sidebar';

export default Sidebar;
