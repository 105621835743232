const validations = {
  givenName: {
    required: {
      value: true,
      message: 'First name required',
    },
  },
  familyName: {
    required: {
      value: true,
      message: 'Last name required',
    },
  },
  email: {
    email: {
      value: true,
      message: 'Invalid email format',
    },
  },
  password: {
    required: {
      value: true,
      message: 'Password is required',
    },
    length: {
      value: 8,
      message:
        "Password must be 8 characters long. Make it a strong one that's easy to remember",
    },
    strength: {
      value: /(?=.*\d)(?=.*[A-Z])(?=.*[a-z])/,
      message:
        'Password must contain numbers, lowercase and uppercase letters.',
    },
  },
  passwordConfirmation: {
    match: {
      value: 'password',
      message: 'Passwords do not match',
    },
  },
  termsAndConditions: {
    required: {
      value: true,
      message: 'You must acknowledge the terms and conditions',
    },
  },
};
export default validations;
