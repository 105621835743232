import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { PartnerAccountInfo } from '../PartnerAccountInfo';
import { useUserContext } from '../../UserProvider';

const EMAIL_ALREADY_EXIST_EXCEPTION = 'UsernameExistsException';

const SignUpWizard = ({ email }) => {
  const { onSignUp } = useUserContext();
  const [serverError, setServerError] = useState(null);
  const [emailError, setEmailError] = useState(null);

  const submit = async (data) => {
    try {
      if (serverError) {
        setServerError(null);
      }
      if (emailError) {
        setEmailError(null);
      }
      await onSignUp(data);
    } catch (err) {
      if (err.code === EMAIL_ALREADY_EXIST_EXCEPTION) {
        setEmailError(err.message);
      } else {
        setServerError(err.message);
      }
    }
  };
  return (
    <PartnerAccountInfo
      email={email}
      emailError={emailError}
      serverError={serverError}
      onSubmit={submit}
    />
  );
};

SignUpWizard.defaultProps = {
  email: '',
};

SignUpWizard.propTypes = {
  email: PropTypes.string,
};

SignUpWizard.displayName = 'SignUpWizard';

export default SignUpWizard;
