import gql from 'graphql-tag';

const LatestNewQuery = gql`
  query News($limit: Int, $slug: String) {
    blogPosts(limit: $limit, slug: $slug) {
      items {
        body
        slug
        title
        heroImage
        publishedAt
      }
    }
  }
`;

export default LatestNewQuery;
