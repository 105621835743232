import gql from 'graphql-tag';

const ResetAppClientSecretMutation = gql`
  mutation ResetAppClientSecret($id: ID!) {
    resetAppClientSecret(id: $id) {
      id
      name
      description
      logo
      url
      client_secret
      redirect_uris
      support_email
      api_contact_email
      website
    }
  }
`;

export default ResetAppClientSecretMutation;
