import React from 'react';
import PropTypes from 'prop-types';
import styles from './Banner.module.scss';

const Banner = ({ children, type, rightAccessory }) => {
  const styleForType =
    type === 'warning' ? `${styles.warning}` : `${styles.info}`;

  return (
    <div className={`${styles.banner} ${styleForType}`}>
      <div className={`${styles['banner-content-container']}`}>
        <span className={`${styles['banner-message']}`}>{children}</span>
        {rightAccessory && rightAccessory}
      </div>
    </div>
  );
};

Banner.defaultProps = {
  rightAccessory: null,
  type: 'info',
};

Banner.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
  rightAccessory: PropTypes.node,
  type: PropTypes.string,
};

export default Banner;
