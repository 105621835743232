import gql from 'graphql-tag';

const LatestNewQuery = gql`
  query News {
    blogPosts(limit: 1) {
      items {
        slug
        title
        heroImage
        publishedAt
      }
    }
  }
`;

export default LatestNewQuery;
