import requireValidation from './requireValidation';
import lengthValidation from './lengthValidation';
import matchValidation from './matchValidation';
import emailValidation from './emailValidation';
import urlsValidation from './urlsValidation';
import strengthValidation from './strengthValidation';

const validationsMap = {
  required: requireValidation,
  length: lengthValidation,
  match: matchValidation,
  email: emailValidation,
  strength: strengthValidation,
  urls: urlsValidation,
};

const isValid = (lastErrors) => {
  return Object.keys(lastErrors).length === 0;
};

const getCriteriaErrors = (criteria, fields, criteriaKey) => {
  if (!criteria) {
    return {};
  }
  return Object.keys(criteria).reduce((prev, next) => {
    const validateFunction = validationsMap[next];
    const error =
      validateFunction && validateFunction(fields, criteriaKey, criteria[next]);
    const previousCriteriaErrors = prev[criteriaKey] || {};
    return error
      ? {
          ...prev,
          [criteriaKey]: {
            ...previousCriteriaErrors,
            [next]: error,
          },
        }
      : { ...prev };
  }, {});
};

const validateFields = (fields, validations) => {
  if (!fields) {
    return {};
  }
  return Object.keys(fields).reduce((prevErrors, key) => {
    const criteria = validations[key];
    const errors = getCriteriaErrors(criteria, fields, key);
    return {
      ...prevErrors,
      ...errors,
    };
  }, {});
};

export * from './constants';
export { validateFields, isValid };
