import { useFlags } from './flags';

const FrameKiller = () => {
  const flags = useFlags();

  if (flags.featurePartnerPortalFramekiller) {
    document.documentElement.style.display = 'none';

    if (window.self === window.top) {
      document.documentElement.style.display = 'block';
    } else {
      window.top.location = window.self.location;
    }
  }
};

export default FrameKiller;
