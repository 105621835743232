import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import Datatable from '@thinkific/toga-datatable';
import SiteRow from './SiteRow';
import { getAccessLinkUrl, authenticateUser, disconnectSite } from './authFlow';
import { apolloClient } from '../../../../config/apollo-client';
import { useUiContext } from '../../../UiContext';
import { graphqlMutationErrors } from '../../../../utils/errors';
import { STATUS } from './constants';

const COLUMNS_TO_HIDE = ['id'];
const COLUMN_DISPLAY_NAMES = {
  name: 'Site name',
  subdomain: 'Subdomain',
  status: 'Status',
};

function SiteDataTable({ data, reloadTable }) {
  const { windowWidth, updateWindowDimensions, showToast } = useUiContext();

  useEffect(() => {
    updateWindowDimensions(window.innerWidth);
    window.addEventListener('resize', updateWindowDimensions);
    return () => {
      window.removeEventListener('resize', updateWindowDimensions);
    };
  });

  async function loginSite(siteId) {
    try {
      const url = await getAccessLinkUrl(siteId, apolloClient);
      authenticateUser(url);
    } catch (e) {
      showToast('alert', graphqlMutationErrors(e));
    }
  }

  async function disconnectClientSite(siteId) {
    try {
      await disconnectSite(siteId, apolloClient);
      await reloadTable();
      showToast('notice', 'Site successfully disconnected', {
        autoClose: false,
      });
    } catch (e) {
      showToast('alert', graphqlMutationErrors(e));
    }
  }

  return (
    <Datatable
      columnsToHide={COLUMNS_TO_HIDE}
      customColumnDisplayName={COLUMN_DISPLAY_NAMES}
      customRowElement={({ rowData, rowItemId }) =>
        rowData.status !== STATUS.DENIED &&
        rowData.status !== STATUS.REVOKED && (
          <SiteRow
            key={rowItemId}
            rowData={rowData}
            rowItemId={rowItemId}
            windowWidth={windowWidth}
            onDisconnect={disconnectClientSite}
            onLogin={loginSite}
          />
        )
      }
      data={data.items}
      hasFrozenColumns
      hasNoTitlebar
      isNotSelectable
    />
  );
}

SiteDataTable.displayName = 'SiteDataTable';

SiteDataTable.propTypes = {
  data: PropTypes.shape({
    items: PropTypes.arrayOf(
      PropTypes.shape({
        __typename: PropTypes.string,
        id: PropTypes.string,
        name: PropTypes.string,
        status: PropTypes.string,
        subdomain: PropTypes.string,
      })
    ),
    nextToken: PropTypes.string,
  }),
  reloadTable: PropTypes.func,
};

SiteDataTable.defaultProps = {
  data: {
    items: [],
    nextToken: null,
  },
  reloadTable: () => {},
};

export default SiteDataTable;
