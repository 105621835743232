import React from 'react';
import PropTypes from 'prop-types';
import { Input } from '@thinkific/toga-react';
import getErrorMessage from './getErrorMessage';

const ValidatedInput = ({ fieldErrors, formText, extraProps, ...props }) => {
  const errorMessage = getErrorMessage(fieldErrors);
  const errorProps = {
    errorText: errorMessage,
    formText: errorMessage ? null : formText,
    extraProps: {
      ...extraProps,
      ...(errorMessage && { isinvalid: 'true' }),
    },
  };

  return <Input {...props} {...errorProps} />;
};

ValidatedInput.propTypes = {
  ...Input.propTypes,
  fieldErrors: PropTypes.object,
};

ValidatedInput.defaultProps = {
  fieldErrors: null,
};

export default ValidatedInput;
