import gql from 'graphql-tag';

const AppsQuery = gql`
  query Apps($limit: Int, $nextToken: String) {
    apps(limit: $limit, nextToken: $nextToken) {
      items {
        id
        name
        description
        logo
        url
      }
      nextToken
    }
  }
`;

export default AppsQuery;
