// TODO: Fix the eslint errors without disable
/* eslint-disable no-unused-vars */

import React from 'react';
import { NavLink } from 'react-router-dom';
import { useQuery } from '@apollo/react-hooks';
import { Col, Row } from '@thinkific/toga-grid';
import CircularSpinner from '@thinkific/toga-circular-spinner';
import TimeAgo from 'react-timeago';
import NewsQuery from './NewsQuery';
import styles from './News.module.scss';
import { Card } from '../Card';

const NewsDashboard = () => {
  const { data, loading } = useQuery(NewsQuery, {
    variables: { limit: 20 },
  });
  const posts = data && data.blogPosts && data.blogPosts.items;

  return (
    <>
      <Row>
        <Col smColumns={12}>
          {loading && <CircularSpinner />}
          {posts &&
            posts.map((post) => (
              <NavLink
                aria-expanded="false"
                key={post.slug}
                to={`/news/${post.slug}`}
                exact
              >
                <Card title={post.title}>
                  <p className={styles.publishedAt}>
                    <TimeAgo date={post.publishedAt} />
                  </p>
                  <img
                    alt={post.title}
                    className={styles.image}
                    src={post.heroImage}
                  />
                </Card>
              </NavLink>
            ))}
        </Col>
      </Row>
    </>
  );
};

NewsDashboard.displayName = 'NewsDashboard';

export default NewsDashboard;
