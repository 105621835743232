const ROUTES = {
  INDEX: {
    PATH: '/',
    TITLE: 'Learn & Connect',
  },
  NEWS: {
    PATH: '/news',
    TITLE: 'News',
  },
  NEWS_DETAILS: {
    PATH: '/news/:slug',
    TITLE: 'News',
  },
  SITES: {
    PATH: '/sites',
    TITLE: 'Sites',
  },
  SITES_MANAGED: {
    PATH: '/sites/managed',
    TITLE: 'Managed sites',
  },
  SITES_DEVELOPMENT: {
    PATH: '/sites/development',
    TITLE: 'Development sites',
  },
  APPS: {
    PATH: '/apps',
    TITLE: 'Apps',
  },
  APP_EDIT: {
    PATH: '/apps/:id/edit',
    TITLE: '',
  },
  APP_CREATE: {
    PATH: '/apps/create',
    TITLE: 'Apps',
  },
  ACCOUNT: {
    PATH: '/account',
    TITLE: 'Account Details',
  },
  CALLBACK: {
    PATH: '/oauth/callback',
    TITLE: 'OAuth Callback',
  },
  ROADMAP: {
    PATH: '/roadmap',
    TITLE: 'Short-Term Roadmap & Feedback',
  },
};

export default ROUTES;
