import React, { useState } from 'react';
import { ModalHeader, ModalFooter, ModalBody } from '@thinkific/toga-modal';
import ReactModal from 'react-modal';
import Button from '@thinkific/toga-button';
import PropTypes from 'prop-types';
import { useUiContext } from '../../UiContext';
import { validateFields } from '../../../utils/validations';
import { graphqlMutationErrors } from '../../../utils/errors';
import { createAppMutationQuery } from './graphql';
import withMutation from '../../../hocs/withMutation';
import { CreateAppForm } from './CreateAppForm';
import { createAppFormStore } from './createAppFormStore';
import styles from './createAppModal.module.scss';
import Icons from '../../Icons';
import { e2eId } from '../../../utils/test.utils';

const validationErrorsExist = (errors) => Object.keys(errors).length > 0;

const formStore = createAppFormStore({
  appName: '',
  appUrl: '',
  redirectUris: '',
});

const CreateAppModal = ({
  closeModal,
  mutation,
  validations,
  onSave,
  onCreate,
}) => {
  const { showToast } = useUiContext();
  const [validationErrors, setValidationErrors] = useState({});
  const createApp = mutation.action;
  const { loading } = mutation.data;

  const resetModalState = () => {
    formStore.clear();
    setValidationErrors({});
  };

  const handleSubmit = async () => {
    try {
      const response = await createApp({
        variables: {
          ...formStore.getState(),
        },
      });
      formStore.clear();
      onSave(response.data.createApp.id);
      onCreate();
    } catch (e) {
      showToast('alert', graphqlMutationErrors(e));
    }
  };

  const verifyAndSubmitForm = async () => {
    const formErrors = validateFields(formStore.getState(), validations);
    if (validationErrorsExist(formErrors)) {
      setValidationErrors(formErrors);
      return;
    }
    setValidationErrors({});
    await handleSubmit();
  };

  const onClose = () => {
    resetModalState();
    closeModal();
  };

  return (
    <ReactModal
      ariaHideApp={false}
      className={styles.container}
      contentLabel="Modal"
      overlayClassName={styles.overlayModal}
      isOpen
      onRequestClose={onClose}
    >
      <ModalHeader className={styles.modalHeader}>
        <h4>Create app</h4>
        <Button
          appearance="utility"
          aria-label="Close"
          className="modal__header__close-button"
          onClick={onClose}
        >
          <Icons.Dismiss />
        </Button>
      </ModalHeader>
      <ModalBody className={styles.modalBody}>
        <CreateAppForm
          formStore={formStore}
          isDisabled={loading}
          validationErrors={validationErrors}
        />
      </ModalBody>
      <ModalFooter className={styles.modalFooter}>
        <Button appearance="secondary" onClick={() => onClose()}>
          Cancel
        </Button>
        <Button
          id="submitBtn"
          type="button"
          onClick={async () => verifyAndSubmitForm()}
          {...e2eId('submitBtn')}
        >
          {loading ? 'Creating' : 'Create'}
        </Button>
      </ModalFooter>
    </ReactModal>
  );
};

CreateAppModal.propTypes = {
  closeModal: PropTypes.func,
  mutation: PropTypes.shape({
    action: PropTypes.func,
    data: PropTypes.object,
  }),
  validations: PropTypes.shape({}),
  onCreate: PropTypes.func,
  onSave: PropTypes.func,
};

CreateAppModal.defaultProps = {
  closeModal: () => {},
  mutation: {
    action: () => {},
    data: {
      loading: false,
      error: false,
      called: false,
    },
  },
  validations: {},
  onSave: () => {},
  onCreate: () => {},
};

export { CreateAppModal };
export default withMutation(CreateAppModal, createAppMutationQuery);
