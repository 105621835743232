import gql from 'graphql-tag';

const accesslinkMutation = gql`
  mutation AccessLink($tenantGlobalId: String!) {
    accessLink(id: $tenantGlobalId) {
      link
    }
  }
`;

export default accesslinkMutation;
