import {
  generateCodeChallenge,
  codeChallengeMethod,
  generateCodeVerifier,
} from '../../../utils/pkce';
import updateThemeExtension from '../../../graphql/Mutation/UpdateThemeExtension';

export const installHandlerURL = (subdomain, clientId, appId) => {
  const codeVerifier = generateCodeVerifier();
  const codeChallenge = generateCodeChallenge(codeVerifier);
  const pkceObj = { codeVerifier, clientId, appId };
  const { protocol, host } = window.location;
  const ppRedirectURL = `${protocol}//${host}/oauth/callback`;
  localStorage.setItem(`${subdomain}.pkceObj`, JSON.stringify(pkceObj));

  return `${protocol}//${subdomain}.${process.env.REACT_APP_THINKIFIC_DOMAIN}/oauth2/authorize?client_id=${clientId}&redirect_uri=${ppRedirectURL}&response_type=code&code_challenge=${codeChallenge}&code_challenge_method=${codeChallengeMethod}`;
};

export const updateThemeExtensionApp = async ({ client, url, appId }) => {
  try {
    const result = await client.mutate({
      variables: { id: appId, url },
      mutation: updateThemeExtension,
    });

    // eslint-disable-next-line camelcase
    const { oauth_app_views, warnings } = result.data.installExtensionPackage;

    return {
      success: true,
      warnings: JSON.parse(warnings),
      oauth_app_views,
    };
  } catch (e) {
    if (!e.graphQLErrors || !e.graphQLErrors.length > 0) {
      // Don't catch any errors that aren't handled graphQL responses
      throw e;
    }

    const errorResponse = JSON.parse(e.graphQLErrors[0].message);
    return {
      success: false,
      ...errorResponse,
    };
  }
};
