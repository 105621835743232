import React from 'react';
import PropTypes from 'prop-types';
import Icon from '@thinkific/toga-icon';

import { ReactComponent as ThinkificLogo } from './assets/thinkific.svg';

const buildIcon = (componentName, WrappedComponent, iconName) => {
  const Component = ({ name, title, ...rest }) => {
    return (
      <WrappedComponent
        name={name || iconName}
        title={title || iconName}
        {...rest}
      />
    );
  };
  Component.propTypes = {
    name: PropTypes.string,
    title: PropTypes.string,
  };
  Component.defaultProps = {
    title: '',
    name: '',
  };
  Component.displayName = `Icons.${componentName}`;
  return { [componentName]: Component };
};

const Icons = {
  ...buildIcon('Dismiss', Icon, 'x-dismiss'),
  ...buildIcon('CircleOutlineInfo', Icon, 'circle-outline-info'),
  ...buildIcon('ThinkificLogo', ThinkificLogo, 'thinkific-logo'),
  ...buildIcon('Plus', Icon, 'plus'),
  ...buildIcon('ArrowUpRight', Icon, 'arrow-up-right'),
};
export default Icons;
