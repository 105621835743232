import React from 'react';
import PropTypes from 'prop-types';
import styles from './TypeformModal.module.scss';
import { useUiContext } from '../../UiContext';

const TypeformModal = ({ url }) => {
  const { closeModal } = useUiContext();

  return (
    <div className={styles.container}>
      <div className={styles.content}>
        <button
          className={styles.close}
          type="button"
          onClick={() => closeModal()}
        >
          <i className="toga-icon toga-icon-x-dismiss large" />
        </button>
        <iframe
          frameBorder="0"
          height="100%"
          src={url}
          title="type form"
          width="100%"
        />
      </div>
    </div>
  );
};

TypeformModal.propTypes = {
  url: PropTypes.string.isRequired,
};

TypeformModal.displayName = 'TypeformModal';

export default TypeformModal;
