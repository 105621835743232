import React, { useState, useCallback, useEffect } from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import { toaster } from '@thinkific/toga-toast';
import { UiContext } from './context';
import { usePageDetailsFactory } from './usePageDetailsFactory';

const UiContextProvider = ({ children }) => {
  const [modalInstance, setModalInstance] = useState(null);
  const defaultPageProps = usePageDetailsFactory({});
  const [pageProps, setPageProps] = useState({});
  const [isSidebarOpen, setSidebar] = useState(false);
  const [windowWidth, setWindowWidth] = useState(0);

  useEffect(() => {
    const pageTitle = document.getElementById('pageTitle');
    pageTitle.innerHTML = `Partner Portal | ${defaultPageProps.title}`;
    return () => {
      pageTitle.innerHTML = 'Partner Portal';
    };
  }, [defaultPageProps]);

  const launchModal = (modal) => {
    setModalInstance(modal);
  };

  const closeModal = () => {
    setModalInstance(null);
  };

  const changePageProps = (props) => {
    setPageProps(props);
  };

  // eslint-disable-next-line consistent-return
  const showToast = (type, text, options = {}) => {
    const openToast =
      typeof toaster[type] === 'function' ? toaster[type] : toaster.notice;
    if (openToast) {
      return openToast(text, options);
    }
  };

  const toggleSidebar = () => {
    setSidebar(!isSidebarOpen);
  };

  const updateWindowDimensions = () => {
    setWindowWidth(window.innerWidth);
  };

  const memoizingChangeHeaderProps = useCallback(changePageProps, []);

  const providerValues = {
    launchModal,
    closeModal,
    modalInstance,
    changePageProps: memoizingChangeHeaderProps,
    pageProps: {
      ...defaultPageProps,
      ...pageProps,
    },
    showToast,
    toggleSidebar,
    isSidebarOpen,
    windowWidth,
    updateWindowDimensions,
  };

  return (
    <UiContext.Provider value={providerValues}>{children}</UiContext.Provider>
  );
};

UiContextProvider.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
};

UiContextProvider.displayName = 'UiContextProvider';

export default withRouter(UiContextProvider);
