import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import * as serviceWorker from './serviceWorker';
import { initializeFlagProvider } from './utils/flags';
import googleTagManagerInit from './utils/googleTagManager';

const render = async () => {
  googleTagManagerInit();
  const FlagProvider = await initializeFlagProvider();
  ReactDOM.render(
    <FlagProvider>
      <App />
    </FlagProvider>,
    document.getElementById('root')
  );
  return true;
};

// Enable live inject
if (module.hot && process.env.NODE_ENV === 'development') {
  module.hot.accept();
}

render();

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
