import gql from 'graphql-tag';

const FilestackSecurityQuery = gql`
  query FilestackPolicy {
    filestackPolicy {
      policy
      signature
    }
  }
`;

export default FilestackSecurityQuery;
