import { MERGE, DISCARD } from './types';

const reducer = (state, action) => {
  const { key, value } = action;
  if (key === MERGE) {
    return {
      ...state,
      ...value,
      modified: false,
      snapshot: {
        ...state,
        ...value,
      },
    };
  }

  if (key === DISCARD) {
    return {
      ...state,
      ...state.snapshot,
      snapshot: {
        ...state.snapshot,
      },
      modified: false,
    };
  }

  const { snapshot } = state;
  return {
    ...state,
    [key]: value,
    modified: snapshot[key] !== value,
  };
};
export default reducer;
