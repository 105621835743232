import React from 'react';
import Button from '@thinkific/toga-button';
import CircularSpinner from '@thinkific/toga-circular-spinner';
import PropTypes from 'prop-types';
import styles from './Account.module.scss';

/**
 * TODO: Maybe turn this part of our cards
 */
const AccountActions = ({
  className,
  saving,
  onCancel,
  onSave,
  cancelLabel,
  saveLabel,
}) => {
  return (
    <footer className={`${styles.footer} ${className}`}>
      <Button
        appearance="secondary"
        data-cy="discardAccountDetails"
        type="button"
        onClick={onCancel}
      >
        {cancelLabel}
      </Button>
      {onSave ? (
        <Button
          appearance="primary"
          data-cy="saveButton"
          disabled={saving}
          id="saveButton"
          type="button"
          onClick={onSave}
        >
          {saving && <CircularSpinner />}
          &nbsp;
          {saveLabel}
          &nbsp;
        </Button>
      ) : (
        <Button
          appearance="primary"
          data-cy="saveButton"
          disabled={saving}
          id="saveButton"
          type="submit"
        >
          {saving && <CircularSpinner />}
          &nbsp;
          {saveLabel}
          &nbsp;
        </Button>
      )}
    </footer>
  );
};
AccountActions.defaultProps = {
  saving: false,
  onCancel: () => {},
  onSave: null,
  cancelLabel: 'Cancel',
  saveLabel: 'Save changes',
  className: '',
};
AccountActions.propTypes = {
  cancelLabel: PropTypes.string,
  className: PropTypes.string,
  saveLabel: PropTypes.string,
  saving: PropTypes.bool,
  onCancel: PropTypes.func,
  onSave: PropTypes.func,
};
export default AccountActions;
