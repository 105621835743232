/* eslint-disable no-console */

import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { COMPACT_MODE_WIDTH } from '@thinkific/toga-datatable';
import InlineActions, { DANGER_TYPE } from '@thinkific/toga-inline-actions';
import { STATUS } from '../constants';

function SiteRow({ rowData, rowItemId, windowWidth, onLogin, onDisconnect }) {
  useEffect(() => {
    document.querySelectorAll('.disabled-action').forEach((item) => {
      setTimeout(() => {
        // eslint-disable-next-line no-param-reassign
        item.querySelector('.action-button').disabled = true;
      }, 0);
    });
  });

  function getRowData() {
    return Object.entries(rowData).map((entry) => {
      switch (entry[0]) {
        case 'name':
          return <td key={`${entry[0]}-${rowItemId}`}>{entry[1]}</td>;
        case 'subdomain':
          return (
            <td key={`${entry[0]}-${rowItemId}`}>
              <a
                href={`https://${entry[1]}.${process.env.REACT_APP_THINKIFIC_DOMAIN}`}
                rel="noopener noreferrer"
                target="_blank"
              >
                {`${entry[1]}.${process.env.REACT_APP_THINKIFIC_DOMAIN}`}
              </a>
            </td>
          );
        case 'status':
          return (
            <td key={`${entry[0]}-${rowItemId}`}>
              {entry[1][0].toUpperCase() + entry[1].substring(1).toLowerCase()}
            </td>
          );
        default:
          return null;
      }
    });
  }

  function getRowActions() {
    const actions = [
      {
        name: 'Disconnect',
        text: 'Disconnect',
        type: DANGER_TYPE,
        onClick: () => onDisconnect(rowData.id),
      },
      {
        name: 'Login',
        text: 'Login',
        onClick: () => {
          onLogin(rowData.id);
        },
        asButton: true,
        disabled: true,
      },
    ];

    return actions;
  }

  return (
    <tr
      className={rowData.status !== STATUS.CONNECTED && 'disabled-action'}
      key={rowItemId}
    >
      {getRowData()}
      <td
        className={`static freeze-right ${
          windowWidth < COMPACT_MODE_WIDTH ? 'frozen-column-compact' : ''
        }`}
      >
        <div className="simple-row__wrapper">
          <InlineActions
            actions={getRowActions()}
            forceMainActionOpen={windowWidth > COMPACT_MODE_WIDTH}
            icon="ellipsis-v"
            isCompactMode={windowWidth < COMPACT_MODE_WIDTH}
          />
        </div>
      </td>
      <td>&nbsp;</td>
    </tr>
  );
}

SiteRow.displayname = 'SiteRow';

SiteRow.propTypes = {
  rowData: PropTypes.shape({
    __typename: PropTypes.string,
    id: PropTypes.string,
    name: PropTypes.string,
    status: PropTypes.string,
    subdomain: PropTypes.string,
  }),
  rowItemId: PropTypes.string,
  windowWidth: PropTypes.number,
  onDisconnect: PropTypes.func,
  onLogin: PropTypes.func,
};

SiteRow.defaultProps = {
  rowData: [],
  rowItemId: '',
  windowWidth: 0,
  onLogin: () => {},
  onDisconnect: () => {},
};

export default SiteRow;
