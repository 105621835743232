/* eslint-disable react/no-unescaped-entities */
import React from 'react';
import { useHistory } from 'react-router-dom';
import PropTypes from 'prop-types';
import { FormRow, Col } from '@thinkific/toga-grid';
import Button from '@thinkific/toga-button';
import Input from '@thinkific/toga-input';
import styles from '../ForgotPassword.module.scss';
import { DEFAULT_ERRORS_CLASSNAME } from '../../../../utils/validations';

const ForgotPasswordForm = ({
  code,
  isInvalidCode,
  isInvalidPassword,
  message,
  onCodeChange,
  onPasswordChange,
  onResetPassword,
  onResetState,
  password,
}) => {
  const history = useHistory();

  return (
    <>
      <p>
        We've sent a verification code to the email you provided. Enter the code
        and your new password below.
      </p>
      <FormRow>
        <Col smColumns={12}>
          <form onSubmit={onResetPassword}>
            <Input
              extraClasses={isInvalidCode ? 'is-invalid' : ''}
              handleChange={onCodeChange}
              handleKeyDown={onCodeChange}
              id="code"
              initValue={code}
              label="Verification code"
              name="code"
              type="text"
            />
            {isInvalidCode && (
              <span className={DEFAULT_ERRORS_CLASSNAME}>{message}</span>
            )}
            <Input
              extraClasses={isInvalidPassword ? 'is-invalid' : ''}
              handleChange={onPasswordChange}
              handleKeyDown={onPasswordChange}
              id="password"
              initValue={password}
              label="New password"
              name="password"
              type="password"
            />
            {isInvalidPassword && (
              <span className={DEFAULT_ERRORS_CLASSNAME}>
                Password must contain at least 1 uppercase letter, 1 lowercase
                letter, 1 number, and be a minimum of 8 characters
              </span>
            )}
            <small className="form-text text-muted" id="passwordHelp">
              Password should be a minimum of 8 characters long, and include
              lowercase and uppercase letters, and a number.
            </small>
          </form>
          <footer className={styles.footer}>
            <span>
              Don't see it?
              <Button
                appearance="link"
                className={styles['link-button']}
                onClick={onResetState}
              >
                Try again
              </Button>
            </span>
            <Button
              appearance="secondary"
              className="mr-2"
              onClick={() => history.push('/welcome/back')}
            >
              Cancel
            </Button>
            <Button onClick={onResetPassword}>Reset password</Button>
          </footer>
        </Col>
      </FormRow>
    </>
  );
};

ForgotPasswordForm.propTypes = {
  code: PropTypes.string.isRequired,
  isInvalidCode: PropTypes.bool.isRequired,
  isInvalidPassword: PropTypes.bool.isRequired,
  message: PropTypes.string.isRequired,
  password: PropTypes.string.isRequired,
  onCodeChange: PropTypes.func.isRequired,
  onPasswordChange: PropTypes.func.isRequired,
  onResetPassword: PropTypes.func.isRequired,
  onResetState: PropTypes.func.isRequired,
};

ForgotPasswordForm.displayName = 'ForgotPasswordForm';

export default ForgotPasswordForm;
