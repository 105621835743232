const validations = {
  givenName: {
    required: {
      value: true,
      message: 'First name required',
    },
  },
  familyName: {
    required: {
      value: true,
      message: 'Last name required',
    },
  },
  email: {
    required: {
      value: true,
      message: 'Email is required',
    },
    email: {
      value: true,
      message: 'Invalid email format',
    },
  },
  website: {
    urls: {
      value: true,
      message: 'Website must contain http(s)://',
    },
  },
};
export default validations;
