import React from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';
import { SignUpWizard } from './SignUpWizard';
import { SignIn } from './SignIn';
import ForgotPassword from './ForgotPassword';
import Icons from '../Icons';
import './Welcome.scss';

const Welcome = () => {
  return (
    <div className="Welcome">
      <div className="Welcome__Container">
        <div className="Welcome__Logo">
          <Icons.ThinkificLogo
            alt="Thinkific"
            className="Welcome__ThinkificLogo"
          />
          <div className="divider" />
          <span>Partners</span>
        </div>
        <Switch>
          <Route
            path="/welcome"
            render={(props) => {
              const url = new URLSearchParams(window.location.search);
              const email = url.get('email');

              if (!email) {
                window.location.href = 'https://www.thinkific.com/partners';
                return null;
              }

              return <SignUpWizard email={email} {...props} />;
            }}
            exact
          />
          <Route component={SignIn} path="/welcome/back" exact />
          <Route component={ForgotPassword} path="/welcome/help" exact />
          {/* <Route exact path="/welcome/reset" component={ResetPassword} /> */}
          <Redirect to="/welcome" />
        </Switch>
      </div>
    </div>
  );
};

Welcome.displayName = 'Welcome';

export default Welcome;
